import { ChevronLeft, ChevronRight, MoreHorizontal } from 'lucide-react'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { Button, ButtonProps } from '@/components/ui'
import { cn } from '@/lib/utils'

type PaginationProps = { nav?: boolean } & React.ComponentProps<'div'>

const Pagination = ({ nav = false, className, ...props }: PaginationProps) => {
	const Component = nav ? 'nav' : 'div'
	return (
		<Component
			role={nav ? 'navigation' : undefined}
			aria-label="pagination"
			className={cn('mx-auto flex w-full justify-center', className)}
			{...props}
		/>
	)
}

Pagination.displayName = 'Pagination'

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(
	({ className, ...props }, ref) => (
		<ul
			ref={ref}
			className={cn('flex flex-row items-center gap-1', className)}
			{...props}
		/>
	),
)
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(({ className, ...props }, ref) => (
	<li
		ref={ref}
		className={cn('', className)}
		{...props}
	/>
))
PaginationItem.displayName = 'PaginationItem'

type PaginationButtonProps = {
	isActive?: boolean
} & ButtonProps

const PaginationButton = ({ className, isActive, size = 'icon', ...props }: PaginationButtonProps) => (
	<Button
		variant={isActive ? 'outline' : 'link'}
		size={size}
		className={cn('rounded-md bg-transparent', isActive && 'border border-black', className)}
		{...props}
	/>
)
PaginationButton.displayName = 'PaginationButton'

type PaginationLinkProps = {
	isActive?: boolean
} & Pick<ButtonProps, 'size'> &
	React.ComponentProps<typeof Link>

const PaginationLink = ({ className, isActive, size = 'icon', ...props }: PaginationLinkProps) => (
	<PaginationButton
		asChild
		isActive={isActive}
		size={size}
		className={className}
	>
		<Link
			aria-current={isActive ? 'page' : undefined}
			{...props}
		/>
	</PaginationButton>
)
PaginationLink.displayName = 'PaginationLink'

type NextPreviousProps =
	| ({
			to?: undefined
	  } & React.ComponentProps<typeof PaginationButton>)
	| React.ComponentProps<typeof PaginationLink>

const PaginationPrevious = ({ className, ...props }: NextPreviousProps) => {
	const commonProps = {
		size: 'default',
		'aria-label': 'Go to previous page',
		className: cn('gap-1 pl-2.5', className),
	} as const

	const children = (
		<>
			<ChevronLeft className="h-4 w-4" />
			<span>Previous</span>
		</>
	)

	return typeof props.to !== 'undefined' ? (
		<PaginationLink
			{...commonProps}
			{...props}
		>
			{children}
		</PaginationLink>
	) : (
		<PaginationButton
			{...commonProps}
			{...props}
		>
			{children}
		</PaginationButton>
	)
}
PaginationPrevious.displayName = 'PaginationPrevious'

const PaginationNext = ({ className, ...props }: NextPreviousProps) => {
	const commonProps = {
		size: 'default',
		'aria-label': 'Go to next page',
		className: cn('gap-1 pl-2.5', className),
	} as const

	const children = (
		<>
			<span>Next</span>
			<ChevronRight className="h-4 w-4" />
		</>
	)

	return typeof props.to !== 'undefined' ? (
		<PaginationLink
			{...commonProps}
			{...props}
		>
			{children}
		</PaginationLink>
	) : (
		<PaginationButton
			{...commonProps}
			{...props}
		>
			{children}
		</PaginationButton>
	)
}
PaginationNext.displayName = 'PaginationNext'

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
	<span
		aria-hidden
		className={cn('flex h-9 w-9 items-center justify-center', className)}
		{...props}
	>
		<MoreHorizontal className="h-4 w-4" />
		<span className="sr-only">More pages</span>
	</span>
)
PaginationEllipsis.displayName = 'PaginationEllipsis'

export {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationButton,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
}
