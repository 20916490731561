import warning from '@/assets/images/warning.svg'
import { cn } from '@/lib/utils'

export type ErrorDisplayProps = React.ComponentProps<'div'> & {
	title?: string
	description?: string
}

export const ErrorDisplay = ({
	title = 'Oops!',
	description = 'Something went wrong. Please try again.',
	className,
	...props
}: ErrorDisplayProps) => {
	return (
		<div
			className={cn('flex flex-col items-center justify-center gap-3 py-3 text-center', className)}
			{...props}
		>
			<img
				src={warning}
				alt=""
			/>
			<h1 className="typography-display-medium text-destructive">{title}</h1>
			<p className="typography-body-large max-w-64 text-primary-900">{description}</p>
		</div>
	)
}
