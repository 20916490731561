import { Landmark } from 'lucide-react'

import { Nullable } from '@/@types/nullable'
import { PurchasePowerResponse } from '@/api/hooks/use-purchase-power.ts'
import { Card } from '@/components/ui'
import { formatCentsToCurrency } from '@/utils/money-utils.ts'
import { SpendingLimitInfo } from './spending-limit-info.tsx'

export type PurchasePowerProps = {
	purchasePower: Nullable<PurchasePowerResponse>
}

export function PurchasePower({ purchasePower }: PurchasePowerProps) {
	return (
		<Card
			data-testid="purchase-power"
			className="flex flex-col gap-12 rounded-lg border-0 bg-blue-linear p-6 text-white shadow-lg"
		>
			<div
				className="flex items-center gap-2"
				data-testid="bank-details"
			>
				<Landmark />
				<span className="typography-label-large-prominent">
					{purchasePower?.account?.vendor_institution_name || 'No account linked'}
				</span>
			</div>
			<div
				className="flex items-end justify-between text-3xl"
				data-testid="bank-balance"
			>
				<div className="flex flex-col gap-1">
					<SpendingLimitInfo />
					<span className="typography-display-small">{formatCentsToCurrency(purchasePower?.purchase_power)}</span>
				</div>
				<div className="typography-label-large">
					{purchasePower?.account?.last_four && <span>#{purchasePower?.account?.last_four}</span>}
				</div>
			</div>
		</Card>
	)
}
