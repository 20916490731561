export const LoadingIcon = () => {
	return (
		<svg
			width="88"
			height="88"
		>
			<circle
				cx="44"
				cy="44"
				r="38"
				fill="none"
				stroke="#BBC8D4"
				strokeWidth="12"
				strokeLinecap="round"
			/>
			<circle
				cx="44"
				cy="44"
				r="38"
				fill="none"
				stroke="#244D74"
				strokeWidth="12"
				strokeLinecap="round"
				strokeDasharray="239.389"
				strokeDashoffset="179.542"
				className="origin-center animate-spin"
			/>
		</svg>
	)
}
