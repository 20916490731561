import { useAuth0 } from '@auth0/auth0-react'

import { useUserEmailVerificationSend } from '@/api/hooks/use-user-send-email-verification.ts'
import NeedHelpLink from '@/components/need-help-link'
import { Button, Input, Label } from '@/components/ui'
import { RegistrationLayout } from './RegistrationLayout.tsx'

export default function VerifyEmail() {
	const { user } = useAuth0()
	const userEmailVerificationSendMutation = useUserEmailVerificationSend()

	return (
		<RegistrationLayout title="Verify Your Email">
			<Label className="pb-2">You're almost there! We sent an email to</Label>
			<Input
				readOnly
				disabled
				type="email"
				value={user?.email}
			/>

			<p className="typography-body-medium pb-6 pt-2">
				Just click on the link in that email to complete your signup. If you don’t see it, you may need to check your
				spam folder.
			</p>

			<Button onClick={() => userEmailVerificationSendMutation.mutate()}>Resend Email</Button>

			<NeedHelpLink className="mt-2" />
		</RegistrationLayout>
	)
}
