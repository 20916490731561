import { useAuth0 } from '@auth0/auth0-react'
import { Navigate } from 'react-router-dom'

import { useBusinessUserByAuthId } from '@/api/hooks/use-business-user-by-auth-id'
import { useUser } from '@/api/hooks/use-user'
import { Loading } from '@/components/loading/index'
import { MyRole } from '@/constants/my-role'
import { usePermissions } from '@/hooks/use-permissions'
import { hasBusinessUserRole } from '@/utils/role-check'

export default function RedirectPage() {
	const { user: auth0User } = useAuth0()
	const { data: dbUser, isLoading: queryRunning } = useUser()
	const { data: businessUser, isLoading: fetchingBusinessUser } = useBusinessUserByAuthId()
	const { paymentCenterEnabled, isLoading: permissionsRunning } = usePermissions()

	if (queryRunning || permissionsRunning || fetchingBusinessUser) {
		return <Loading />
	}

	const userRoles: MyRole[] = auth0User?.['paybotic_banking_roles'] || []
	const bu = hasBusinessUserRole(userRoles)
	if (paymentCenterEnabled === false) {
		return <Navigate to="/unauthenticated" />
	}
	if (bu && businessUser) {
		return <Navigate to="/merchant" />
	}
	if (bu && !businessUser) {
		return <Navigate to="/merchant/registration" />
	}

	if (!dbUser || !auth0User?.email_verified) {
		return <Navigate to="/registration" />
	}

	if (!dbUser.has_created_external_account) {
		return <Navigate to="/link-account" />
	}

	return <Navigate to="/dashboard" />
}
