import { Transaction } from '@/api/hooks/use-transaction'
import { Button, Skeleton } from '@/components/ui'
import { Transaction as TransactionItem } from './Transaction'

type TransactionsListProps = {
	data: Transaction[]
	isLoading: boolean
	showSeeMoreButton?: boolean
	seeMoreOnClick: () => void
}

export function TransactionsList({ data, isLoading, showSeeMoreButton, seeMoreOnClick }: TransactionsListProps) {
	if (isLoading) {
		return (
			<div className="flex flex-col gap-2">
				<Skeleton className="h-5 w-full" />
				<Skeleton className="h-[267px] w-full" />
			</div>
		)
	}

	if (!data.length) return null

	return (
		<div data-testid="transactions-list">
			{data.map((transaction, index) => (
				<TransactionItem
					key={index}
					{...transaction}
				/>
			))}
			{showSeeMoreButton && (
				<div className="border-t border-t-primary-100 pt-4">
					<Button
						size="lg"
						data-testid="see-all-transactions"
						variant="secondary"
						className="w-full"
						onClick={seeMoreOnClick}
					>
						See more
					</Button>
				</div>
			)}
		</div>
	)
}
