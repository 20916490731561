import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowLeft } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { Support } from '@/@types/support'
import { useAccounts } from '@/api/hooks/use-accounts'
import { useSupport } from '@/api/hooks/use-support'
import { Loading } from '@/components/loading'
import { Button, Form, FormComponent, Textarea } from '@/components/ui'
import { DashboardLayout } from '@/modules/consumer/dashboard/dashboard-layout.tsx'

const supportFormSchema = z.object({
	notes: z.string().min(10, 'We need more information'),
})

type ConsumerSupport = Pick<Support, 'notes'>

export const ContactSupport = () => {
	const { data, isLoading: isLoadingAccounts } = useAccounts()
	const navigate = useNavigate()

	const form = useForm<ConsumerSupport>({
		resolver: zodResolver(supportFormSchema),
	})

	const onSuccess = () => {
		form.reset({
			notes: '',
		})
	}
	const supportMutation = useSupport(onSuccess)

	if (isLoadingAccounts || !Array.isArray(data)) {
		return <Loading />
	}

	const onSubmit = async (data: ConsumerSupport) => {
		supportMutation.mutate(data)
	}

	return (
		<DashboardLayout>
			<div className="flex flex-col gap-6">
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="space-y-md"
					>
						<div className="mb-6 flex justify-between">
							<Button
								data-testid="back-button"
								onClick={() => navigate('/dashboard/settings')}
								type="button"
								variant="secondary"
								size="icon"
							>
								<ArrowLeft />
							</Button>
						</div>
						<h1 className="typography-headline-medium text-primary-500">Contact Support</h1>
						<FormComponent
							control={form.control}
							name="notes"
							label="Need support? Send us a note below."
							required
							render={({ field }) => (
								<Textarea
									placeholder="Tell us what’s on your mind..."
									{...field}
								/>
							)}
						/>
						<p className="text-label-large text-neutral-900">Or email us at support@payboticfinancial.com.</p>
						<Button
							className="w-full"
							type="submit"
						>
							Submit
						</Button>
					</form>
				</Form>
			</div>
		</DashboardLayout>
	)
}
