import { createColumnHelper } from '@tanstack/react-table'
import { KeySquare, MoreVertical, Trash, UserPen } from 'lucide-react'
import { useMemo, useState } from 'react'

import { BusinessUser, useBusinessUserByAuthId } from '@/api/hooks/use-business-user-by-auth-id.ts'
import { useBusinessUsers } from '@/api/hooks/use-business-users.ts'
import { DataTable } from '@/components/data-table/'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header.tsx'
import {
	Button,
	Card,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	Separator,
} from '@/components/ui'
import { MyPermissions } from '@/constants/my-permissions.ts'
import { canEditPassword, getNameFromAuthRoles, getNameFromRoles, getRoleLabelFromName } from '@/constants/my-role.ts'
import { usePermissions } from '@/hooks/use-permissions.ts'
import { cn } from '@/lib/utils.ts'
import { ChangePasswordDialog } from '@/modules/merchant/users/change-password.tsx'
import { DeleteUserDialog } from '@/modules/merchant/users/delete-user.tsx'
import { ChangeRoleDialog } from './change-role.tsx'

const columnHelper = createColumnHelper<BusinessUser>()

type Props = {
	businessId: number
}

const isUserOrCreatedBy = ({
	auth0ID,
	businessUserAuth0Id,
	businessUserCreatedBy,
}: {
	auth0ID: string
	businessUserAuth0Id: string
	businessUserCreatedBy: string | undefined
}) => businessUserAuth0Id === auth0ID || businessUserCreatedBy === auth0ID

export const BusinessUsersTable = ({ businessId }: Props) => {
	const [selectedUser, setSelectedUser] = useState<BusinessUser | null>(null)
	const [showRoleDialog, setShowRoleDialog] = useState(false)
	const [showPasswordDialog, setShowPasswordDialog] = useState(false)
	const [showDeleteDialog, setShowDeleteDialog] = useState(false)

	const user = useBusinessUserByAuthId()
	const businessUsers = useBusinessUsers(businessId)
	const { permissions, decodedToken } = usePermissions()
	const businessUserRole = getNameFromRoles(decodedToken?.paybotic_banking_roles)

	const allowEdit = permissions?.includes(MyPermissions.UpdateBusinessUsers)
	const allowDelete = permissions?.includes(MyPermissions.DeleteBusinessUsers)

	const columns = useMemo(
		() => [
			columnHelper.accessor('Username', {
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Username"
					/>
				),
				enableSorting: false,
				meta: {
					className: cn('whitespace-nowrap'),
				},
			}),
			columnHelper.accessor('Roles', {
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Role"
					/>
				),
				cell: ({ cell }) => {
					const role = getNameFromAuthRoles(cell.getValue())
					return getRoleLabelFromName(role)
				},
				enableSorting: false,
				meta: {
					className: cn('whitespace-nowrap'),
				},
			}),
			columnHelper.accessor('Email', {
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Email"
					/>
				),
				enableSorting: false,
			}),
			columnHelper.display({
				filterFn: () => false,
				id: 'actions',
				cell: ({ row }) => {
					const rowUser = row.original
					const rowRole = getNameFromAuthRoles(rowUser.Roles)

					const allowEditRow =
						user.data &&
						!isUserOrCreatedBy({
							auth0ID: rowUser.Auth0ID,
							businessUserAuth0Id: user.data.Auth0ID,
							businessUserCreatedBy: decodedToken?.['https://paybotic/createdBy'],
						})
					const allowEditRowPassword = allowEditRow && canEditPassword(businessUserRole, rowRole)
					const allowDeleteRow = allowEditRow && allowDelete

					return (
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button
									variant="secondary"
									className="h-8 w-8 border p-0"
									aria-label="open actions"
									disabled={!allowEditRow}
								>
									<MoreVertical className="h-4 w-4" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
								<DropdownMenuItem
									className="px-4 py-3"
									disabled={!allowEditRow}
									onClick={() => {
										setSelectedUser(rowUser)
										setShowRoleDialog(true)
									}}
								>
									<UserPen />
									Change User Role
								</DropdownMenuItem>
								<DropdownMenuItem
									disabled={!allowEditRowPassword}
									className="px-4 py-3"
									onClick={() => {
										setSelectedUser(rowUser)
										setShowPasswordDialog(true)
									}}
								>
									<KeySquare />
									Change Password
								</DropdownMenuItem>
								<Separator orientation={'horizontal'} />
								<DropdownMenuItem
									disabled={!allowDeleteRow}
									className="px-4 py-3 text-red-600"
									onClick={() => {
										setSelectedUser(rowUser)
										setShowDeleteDialog(true)
									}}
								>
									<Trash />
									Delete User
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					)
				},
				meta: {
					className: cn('w-0'),
				},
			} as const),
		],
		[allowDelete, businessUserRole, decodedToken, user.data],
	)

	return (
		<div className="space-y-4">
			<Card
				className="rounded-md border p-sm shadow-none"
				data-testid="merchant-users-table"
			>
				<DataTable
					isLoading={businessUsers.isLoading}
					columns={columns}
					data={businessUsers.data ?? []}
					initialState={{
						columnVisibility: {
							actions: allowEdit,
						},
					}}
				/>
			</Card>

			{selectedUser && (
				<ChangeRoleDialog
					open={showRoleDialog}
					onOpenChange={setShowRoleDialog}
					username={selectedUser.Username}
					currentRole={selectedUser.Roles}
					selectedUser={selectedUser}
				/>
			)}
			{selectedUser && (
				<ChangePasswordDialog
					open={showPasswordDialog}
					onOpenChange={setShowPasswordDialog}
					username={selectedUser.Username}
					selectedUser={selectedUser}
				/>
			)}
			{selectedUser && (
				<DeleteUserDialog
					open={showDeleteDialog}
					onOpenChange={setShowDeleteDialog}
					username={selectedUser.Username}
					selectedUser={selectedUser}
				/>
			)}
		</div>
	)
}
