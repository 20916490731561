import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowLeft } from 'lucide-react'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as z from 'zod'

import { useUser } from '@/api/hooks/use-user'
import { useUpdateUserInformation } from '@/api/hooks/use-user-update-self'
import { Loading } from '@/components/loading'
import {
	Button,
	Combobox,
	DatePicker,
	Form,
	FormComponent,
	FormControl,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
} from '@/components/ui'
import { cn } from '@/lib/utils'
import { User } from '@/models/user.models'
import { DashboardLayout } from '@/modules/consumer/dashboard/dashboard-layout'
import { usStates } from '@/utils/us-states'

const PersonalInformationSchema = z.object({
	first_name: z.string().min(1, 'First name is required'),
	last_name: z.string().min(1, 'Last name is required'),
	birthday: z.date({
		required_error: 'Birthday is required',
	}),
	phone_number: z.string().min(10, 'Invalid phone number'),
	street_one: z.string().min(1, 'Street address is required'),
	street_two: z.string().optional(),
	city: z.string().min(1, 'City is required'),
	state: z.string().min(1, 'State is required'),
	postal_code: z.string().min(5, 'Invalid zip code'),
})

type PersonalInformationForm = z.infer<typeof PersonalInformationSchema>

export const PersonalInformationForm = ({ user }: { user: User }) => {
	const navigate = useNavigate()
	const updateUserMutation = useUpdateUserInformation()

	const defaultValues = useMemo(
		() =>
			({
				email: user.email || '',
				first_name: user.first_name || '',
				last_name: user.last_name || '',
				birthday: user.date_of_birth ? new Date(`${user.date_of_birth.split('T')[0]}T12:00:00Z`) : undefined,
				phone_number: user.phone_number || '',
				street_one: user.address?.street_one || '',
				street_two: user.address?.street_two || '',
				city: user.address?.city || '',
				state: user.address?.state || '',
				postal_code: user.address?.postal_code || '',
			}) as const,
		[user],
	)

	const form = useForm<PersonalInformationForm>({
		resolver: zodResolver(PersonalInformationSchema),
		defaultValues,
	})

	const onSubmit = useCallback(
		(data: PersonalInformationForm) => updateUserMutation.mutate(data, { onSuccess: () => form.reset(data) }),
		[form, updateUserMutation],
	)

	const handleCancel = useCallback(() => {
		form.reset(defaultValues)
	}, [defaultValues, form])

	return (
		<DashboardLayout className="!pt-8">
			<div className="flex flex-col gap-6">
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="space-y-2"
					>
						<div className="mb-6 flex justify-between">
							<Button
								data-testid="back-button"
								onClick={() => navigate('/dashboard/settings')}
								type="button"
								variant="secondary"
								size="icon"
							>
								<ArrowLeft />
							</Button>
							<div
								data-testid="personal-information-action"
								className={cn('*flex-1 invisible flex justify-end gap-2 *:w-28', form.formState.isDirty && 'visible')}
							>
								<Button
									variant="secondary"
									type="button"
									onClick={handleCancel}
								>
									Cancel
								</Button>
								<Button type="submit">Save</Button>
							</div>
						</div>
						<h1 className="typography-headline-medium text-primary-500">Personal Information</h1>
						<FormItem>
							<FormLabel>Email</FormLabel>
							<FormControl>
								<Input
									readOnly
									disabled
									type="email"
									value={user?.email}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>

						<FormComponent
							control={form.control}
							name="first_name"
							label="First Name"
							required
							render={({ field }) => (
								<Input
									className=""
									placeholder="First name"
									{...field}
								/>
							)}
						/>

						<FormComponent
							control={form.control}
							name="last_name"
							label="Last Name"
							required
							render={({ field }) => (
								<Input
									className=""
									placeholder="Last name"
									{...field}
								/>
							)}
						/>
						<FormComponent
							control={form.control}
							name="birthday"
							label="Birthday"
							required
							render={({ field }) => (
								<DatePicker
									value={field.value}
									onChange={field.onChange}
								/>
							)}
						/>

						<FormComponent
							control={form.control}
							name="phone_number"
							label="Phone Number"
							required
							render={({ field }) => (
								<Input
									className=""
									placeholder="(123) 456-7890"
									{...field}
								/>
							)}
						/>

						<FormComponent
							control={form.control}
							name="street_one"
							label="Street Address"
							required
							render={({ field }) => (
								<Input
									className=""
									placeholder="Street address"
									{...field}
								/>
							)}
						/>

						<FormComponent
							control={form.control}
							name="street_two"
							label="Address Line 2"
							render={({ field }) => (
								<Input
									className=""
									placeholder="Address line 2"
									{...field}
								/>
							)}
						/>

						<FormComponent
							control={form.control}
							name="city"
							label="City"
							required
							render={({ field }) => (
								<Input
									className=""
									placeholder="City"
									{...field}
								/>
							)}
						/>

						<FormComponent
							control={form.control}
							name="state"
							label="State"
							required
							render={({ field }) => (
								<Combobox
									options={usStates}
									value={field.value}
									onValueChange={field.onChange}
								/>
							)}
						/>

						<FormComponent
							control={form.control}
							name="postal_code"
							label="Zip Code"
							required
							render={({ field }) => (
								<Input
									className=""
									placeholder="Zip code"
									{...field}
								/>
							)}
						/>
					</form>
				</Form>
			</div>
		</DashboardLayout>
	)
}

export const PersonalInformation = () => {
	const user = useUser()

	if (user.isLoading || !user.data) {
		return <Loading />
	}

	return <PersonalInformationForm user={user.data} />
}
