import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { DayPickerRangeProps } from 'react-day-picker'

import { Button, Calendar, Popover, PopoverContent, PopoverTrigger } from '@/components/ui'
import { cn } from '@/lib/utils'

export function DateRangeInput({ className, ...props }: Omit<DayPickerRangeProps, 'mode'>) {
	const { from, to } = props.selected || {}

	return (
		<div className={cn('grid gap-2', className)}>
			<Popover>
				<PopoverTrigger asChild>
					<Button
						variant="outline"
						className={cn('justify-start text-left font-normal', !props.selected && 'text-muted-foreground')}
						aria-label="Pick a date"
					>
						<CalendarIcon />
						{from ? (
							to ? (
								<>
									{format(from, 'LLL dd, y')} - {format(to, 'LLL dd, y')}
								</>
							) : (
								format(from, 'LLL dd, y')
							)
						) : (
							<span>Pick a date</span>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent
					className="w-auto p-0"
					align="start"
				>
					<Calendar
						initialFocus
						mode="range"
						numberOfMonths={2}
						defaultMonth={from}
						{...props}
					/>
				</PopoverContent>
			</Popover>
		</div>
	)
}
