import { Info } from 'lucide-react'
import { useState } from 'react'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui'

export function SpendingLimitInfo() {
	const [open, setOpen] = useState(false)

	const handleTooltipOpen = () => {
		setOpen(true)
		setTimeout(() => {
			setOpen(false)
		}, 3000)
	}

	return (
		<TooltipProvider>
			<div className="flex items-center gap-1">
				<span className="typography-label-large">SPENDING LIMIT</span>
				<Tooltip
					open={open}
					onOpenChange={setOpen}
				>
					<TooltipTrigger asChild>
						<span
							className="p-0"
							onClick={handleTooltipOpen}
						>
							<Info className="h-4 w-4" />
						</span>
					</TooltipTrigger>
					<TooltipContent>
						<p>$500 daily limit, minus pending purchases</p>
					</TooltipContent>
				</Tooltip>
			</div>
		</TooltipProvider>
	)
}
