import { Button, ButtonProps } from '@/components/ui'

export default function NeedHelpLink(props: ButtonProps) {
	return (
		<Button
			variant="link"
			{...props}
		>
			<a href="mailto:support@payboticfinancial.com">
				Need Help? <span className="underline">Contact Us.</span>
			</a>
		</Button>
	)
}
