import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { CurrencyInput } from '@/components/inputs/currency-input'
import { Button, Form, Input, Textarea, useFormComponentControlled } from '@/components/ui'
import { PurchaseInfo, purchaseInfoSchema, useMerchantCheckoutStore } from '@/store/merchant/merchantCheckoutStore'

const defaultValues: PurchaseInfo = {
	price: 0,
	employeeName: '',
	posOrderId: '',
	notes: '',
}

export function MerchantCheckoutPage() {
	const navigate = useNavigate()
	const setPurchase = useMerchantCheckoutStore((state) => state.setPurchase)
	const purchase = useMerchantCheckoutStore((state) => state.purchase)

	const form = useForm<PurchaseInfo>({
		defaultValues: purchase || defaultValues,
		resolver: zodResolver(purchaseInfoSchema),
	})
	const FormComponent = useFormComponentControlled(form.control)

	const onSubmit = (data: PurchaseInfo) => {
		setPurchase(data)
		navigate('/merchant/checkout/code')
	}

	return (
		<div className="container flex w-full flex-col gap-md p-4 xl:p-8">
			<h1 className="typography-headline-medium text-primary">Checkout</h1>
			<div className="space-y-2 rounded-lg bg-card p-6">
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="space-y-4"
					>
						<FormComponent
							name="employeeName"
							label="Employee Name"
							render={({ field }) => (
								<Input
									{...field}
									placeholder="Name of Employee"
								/>
							)}
						/>
						<FormComponent
							name="price"
							label="Total Price"
							render={({ field }) => <CurrencyInput {...field} />}
						/>
						<FormComponent
							name="posOrderId"
							label="POS Order ID (optional)"
							render={({ field }) => (
								<Input
									{...field}
									placeholder="POS Order ID"
								/>
							)}
						/>
						<FormComponent
							name="notes"
							label="Notes (optional)"
							render={({ field }) => (
								<Textarea
									{...field}
									placeholder="Enter Notes"
								/>
							)}
						/>
						<div className="space-y-2">
							<Button
								className="w-full"
								type="submit"
							>
								Checkout
							</Button>
							<Button
								variant="link"
								className="w-full"
								type="button"
								onClick={() => form.reset(defaultValues)}
							>
								Reset Fields
							</Button>
						</div>
					</form>
				</Form>
			</div>
		</div>
	)
}
