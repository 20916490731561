import {
	Badge,
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '@/components/ui'

export function AboutRolesDialog() {
	return (
		<Dialog>
			<DialogTrigger
				asChild
				className="text-sm text-muted-foreground hover:text-primary"
			>
				<Button
					className="text-black"
					variant={'secondary'}
				>
					About User Roles
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle className="mb-4 text-center">
						<p className="typography-title-large">About User Roles</p>
					</DialogTitle>
				</DialogHeader>
				<div className="space-y-10">
					<div className="space-y-2">
						<h4 className="font-medium">Store Viewers</h4>
						<p className="text-sm text-muted-foreground">
							Store Viewers are users who are in charge of checking out customers.
						</p>
						<div className="flex flex-wrap items-center gap-2">
							Pages:
							<Badge variant="outline">Home</Badge>
							<Badge variant="outline">Checkout</Badge>
							<Badge variant="outline">Support</Badge>
						</div>
					</div>
					<div className="space-y-2">
						<h4 className="font-medium">Account Viewers</h4>
						<p className="text-sm text-muted-foreground">Account Viewers are users who need access to reporting.</p>
						<div className="flex flex-wrap items-center gap-2">
							Pages:
							<Badge variant="outline">Home</Badge>
							<Badge variant="outline">Reports</Badge>
							<Badge variant="outline">Support</Badge>
						</div>
					</div>
					<div className="space-y-2">
						<h4 className="font-medium">Store and Account Admins</h4>
						<p className="text-sm text-muted-foreground">Admins are users who need the ability to manage users.</p>
						<div className="flex flex-wrap items-center gap-2">
							Pages:
							<Badge variant="outline">Home</Badge>
							<Badge variant="outline">Users</Badge>
							<Badge variant="outline">Checkout</Badge>
							<Badge variant="outline">Reports</Badge>
							<Badge variant="outline">Support</Badge>
						</div>
					</div>
				</div>
				<DialogFooter className="mt-8 w-full">
					<DialogClose asChild>
						<Button
							className="w-full"
							type="button"
						>
							Close
						</Button>
					</DialogClose>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
