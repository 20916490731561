import { useEffect, useState } from 'react'
import QrReader from 'react-qr-scanner'

import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui'
import { AspectRatio } from '@/components/ui/aspect-ratio/aspect-ratio.tsx'
import { useIsMobile } from '@/hooks/use-is-mobile.ts'
import { CameraDirection, MediaDeviceInfo, MyConstraint } from './qr-types.ts'
import { useAvailableCameras } from './useAvailableCameras.ts'

export type CameraOption = {
	label: string
	value: string
}

const cameraDirectionValue = {
	BACK: 'environment',
	FRONT: 'user',
}

const cameraDirectionOptions: CameraDirection[] = [
	{ value: cameraDirectionValue.BACK, name: 'Back Camera' },
	{ value: cameraDirectionValue.FRONT, name: 'Front Camera' },
]

type Props = {
	callback: (data: string) => void
}

export const ReadQrCode = ({ callback }: Props) => {
	const [facingMode, setFacingMode] = useState<string>(cameraDirectionValue.BACK)
	const availableDevices = useAvailableCameras()
	const [device, setDevice] = useState<MediaDeviceInfo | undefined>(undefined)
	const { isMobile } = useIsMobile()

	useEffect(() => {
		if (availableDevices.devices.length > 0) {
			const firstBackDevice = availableDevices.devices.find((d) => d.label.toLowerCase().includes('back'))
			setDevice(firstBackDevice || availableDevices.devices[0])
		}
	}, [availableDevices])

	if (availableDevices.loading || device === undefined) {
		return null
	}

	let constraints: MyConstraint = { audio: false }
	let cameraOptions: CameraOption[]
	if (isMobile) {
		constraints = { ...constraints, video: { facingMode } }
		cameraOptions = cameraDirectionOptions.map((o) => ({
			label: o.name,
			value: o.value,
		}))
	} else {
		constraints = { ...constraints, video: { deviceId: device.deviceId } }
		cameraOptions = availableDevices.devices.map((d) => ({
			label: d.label,
			value: d.deviceId,
		}))
	}

	return (
		<>
			<div className="absolute left-0 top-0 z-10 h-full w-full overflow-hidden">
				<div className="m-auto flex h-full w-full max-w-md flex-col items-center gap-4 p-4 pt-36">
					<Select
						value={isMobile ? facingMode : device.deviceId}
						onValueChange={(value) => {
							if (isMobile) {
								setFacingMode(value)
							} else {
								setDevice(availableDevices.devices.find((d) => d.deviceId === value))
							}
						}}
					>
						<SelectTrigger className="w-full">
							<SelectValue placeholder="Select camera" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{cameraOptions.map(({ label, value }) =>
									value ? (
										<SelectItem
											key={value}
											value={value}
										>
											{label}
										</SelectItem>
									) : null,
								)}
							</SelectGroup>
						</SelectContent>
					</Select>

					<div className="w-full rounded border-2 border-primary-100 shadow-[0_0_0_100vmax] shadow-white/40">
						<AspectRatio
							ratio={1}
							className="rounded"
						/>
					</div>
				</div>
			</div>

			<QrReader
				data-testid="ReadQrCode"
				constraints={constraints}
				delay={100}
				className="absolute top-0 h-full w-full object-cover"
				onError={console.error}
				onScan={(data: { text: string }) => {
					if (!data) return
					callback(data.text)
				}}
			/>
		</>
	)
}
