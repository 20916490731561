import { Outlet } from 'react-router-dom'

import { Header } from './ConsumerHeader.tsx'
import { Navigation } from './ConsumerNavigation.tsx'

export function ConsumerLayout() {
	return (
		<>
			<Header />
			<main
				className="pb-24 pt-header"
				data-testid="AuthenticatedLayout-Main"
			>
				<Outlet />
			</main>
			<Navigation />
		</>
	)
}
