import { useQueryClient } from '@tanstack/react-query'
import { HTTPError } from 'ky'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Nullable } from '@/@types/nullable'
import { useConfirmPayment, useDenyPayment } from '@/api/hooks/use-payment'
import { PurchasePowerResponse } from '@/api/hooks/use-purchase-power.ts'
import { Button } from '@/components/ui'
import { useConsumerPurchaseStore } from '@/store/consumer/consumerPurchaseStore'
import { formatCentsToCurrency } from '@/utils/money-utils'

export const ConfirmPayment = () => {
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const { mutate: mutateConfirm, isPending: isPendingConfirm } = useConfirmPayment()
	const { mutate: mutateDeny, isPending: isPendingDeny } = useDenyPayment()
	const setPurchaseError = useConsumerPurchaseStore((state) => state.setError)
	const confirmationStatus = useConsumerPurchaseStore((state) => state.confirmationStatus)
	const setConfirmationStatus = useConsumerPurchaseStore((state) => state.setConfirmationStatus)

	useEffect(() => {
		if (!confirmationStatus?.purchaseId) {
			navigate('/dashboard')
		}
	}, [confirmationStatus?.purchaseId, navigate])

	const onCancel = useCallback(() => {
		if (!confirmationStatus?.purchaseId) {
			return
		}
		mutateDeny({
			purchaseId: confirmationStatus?.purchaseId,
		})
		navigate('/dashboard')
	}, [mutateDeny, navigate, confirmationStatus?.purchaseId])

	const onConfirm = useCallback(() => {
		if (!confirmationStatus?.purchaseId) {
			return
		}
		mutateConfirm(
			{
				purchaseId: confirmationStatus?.purchaseId,
			},
			{
				onSuccess: () => {
					setConfirmationStatus({
						...confirmationStatus,
						status: 'COMPLETED',
					})
					queryClient.setQueryData<Nullable<PurchasePowerResponse>>(['purchase_power'], (oldData) => {
						if (!oldData) {
							return null
						}
						return { ...oldData, purchase_power: oldData.purchase_power - confirmationStatus.amount }
					})
					navigate('/payment/success')
				},
				onError: async (error) => {
					let message = 'An error occurred'
					if (error instanceof HTTPError) {
						const res = await error?.response?.json()
						if (res?.message) {
							message = res?.message
						}
					}
					setPurchaseError(message)
					navigate('/payment/error')
				},
			},
		)
	}, [mutateConfirm, navigate, confirmationStatus, setPurchaseError, setConfirmationStatus])

	const isLoading = isPendingConfirm || isPendingDeny

	if (!confirmationStatus) {
		return null
	}

	return (
		<div className="m-auto flex h-full max-w-lg flex-col gap-24 p-4 pt-10 md:gap-40">
			<div className="flex flex-1 flex-col gap-6 text-primary-900">
				<h1 className="typography-display-medium text-center">Confirm your order</h1>
				<p className="typography-body-medium">
					Please confirm your order with {confirmationStatus.businessName} using{' '}
					{confirmationStatus.customerAccountInstitutionName} #{confirmationStatus.customerAccountLastFour}:
				</p>
				<div className="flex flex-col items-center justify-center space-y-2 rounded-md border border-primary-300 p-lg">
					<span className="typography-title-medium">Order Amount</span>
					<span
						className="typography-display-small text-primary"
						data-testid="order-details-value-amount"
					>
						{formatCentsToCurrency(confirmationStatus.amount)}
					</span>
				</div>
				<p className="typography-body-medium">
					By confirming, you acknowledge and agree to the Consumer ACH Processing{' '}
					<a
						className="text-primary underline"
						target="_blank"
						href="https://payboticfinancial.com/terms-and-conditions/"
					>
						Terms and Conditions
					</a>{' '}
					established by Paybotic Financial for this transaction.
				</p>
			</div>
			<div className="mt-auto flex flex-col gap-sm">
				<Button
					className="w-full"
					variant="tertiary"
					onClick={onConfirm}
					disabled={isLoading}
				>
					Confirm Order
				</Button>
				<Button
					className="w-full"
					variant="link"
					onClick={onCancel}
					disabled={isLoading}
				>
					Cancel
				</Button>
			</div>
		</div>
	)
}
