import { Tabs, TabsContent } from '@/components/ui'
import { TransactionsReport } from '@/modules/merchant/reports/transactions-report'

export function MerchantReportsPage() {
	return (
		<div className="container flex w-full flex-col gap-md p-4 xl:p-8">
			<h1 className="typography-headline-medium text-primary">Reports</h1>
			<Tabs defaultValue="transactions">
				{/* Uncomment when Returns Report is implemented */}
				{/* <TabsList className="grid w-full grid-cols-2">
					<TabsTrigger value="transactions">Transactions Report</TabsTrigger>
					<TabsTrigger value="returns">Returns Report</TabsTrigger>
				</TabsList> */}
				<TabsContent value="transactions">
					<TransactionsReport />
				</TabsContent>
			</Tabs>
		</div>
	)
}
