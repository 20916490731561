import { Plus } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

import { useAccounts } from '@/api/hooks/use-accounts'
import { Button, Skeleton } from '@/components/ui'
import { AccountCard } from '@/modules/consumer/dashboard/account-card'
import { DashboardLayout } from '@/modules/consumer/dashboard/dashboard-layout'

export function AccountsPage() {
	const { data, isLoading } = useAccounts()
	const navigate = useNavigate()

	return (
		<DashboardLayout>
			<div className="flex items-center justify-between">
				<h1 className="typography-headline-medium text-primary-500">Your accounts</h1>
				<Button
					data-testid="add-account"
					variant="secondary"
					size="icon"
					onClick={() => navigate('/link-account')}
				>
					<Plus />
				</Button>
			</div>
			<div className="flex flex-col items-center justify-center gap-3 py-3">
				{isLoading ? (
					<Skeleton className="h-[118px] w-full bg-blue-linear" />
				) : (
					<>
						{(data || []).map((account) => (
							<AccountCard
								key={account.ID}
								account={account}
							/>
						))}
					</>
				)}
			</div>
		</DashboardLayout>
	)
}
