import { create } from 'zustand'

import { PurchaseWebsocketStatus } from '@/@types/purchase'
import { InitialPurchaseResponse } from '@/api/hooks/use-purchase'

export type ConsumerPurchaseStore = {
	purchase: InitialPurchaseResponse | null
	setPurchase: (purchase: InitialPurchaseResponse | null) => void
	confirmationStatus: PurchaseWebsocketStatus | null
	setConfirmationStatus: (status: PurchaseWebsocketStatus | null) => void
	error: string | null
	setError: (purchaseError: string | null) => void
}

export const useConsumerPurchaseStore = create<ConsumerPurchaseStore>((set) => ({
	purchase: null,
	setPurchase: (purchase) => set({ purchase }),
	confirmationStatus: null,
	setConfirmationStatus: (confirmationStatus) => set({ confirmationStatus }),
	error: null,
	setError: (error) => set({ error }),
	result: null,
}))
