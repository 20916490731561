import { Button } from '@/components/ui'
import { cn } from '@/lib/utils'

export type RegistrationFormLayoutProps = React.ComponentProps<'form'> & { isSubmitting?: boolean }

export function RegistrationFormLayout({ children, className, isSubmitting, ...props }: RegistrationFormLayoutProps) {
	return (
		<form
			className={cn('relative flex h-full min-h-0 flex-col bg-primary-50', className)}
			{...props}
		>
			<div className="-mx-4 flex min-h-0 flex-shrink flex-col space-y-2 overflow-y-auto px-4 pb-24">{children}</div>

			<div className="absolute bottom-0 left-0 right-0 flex justify-center bg-inherit py-6">
				<Button
					disabled={isSubmitting}
					type="submit"
					className="w-full"
				>
					Continue
				</Button>
			</div>
		</form>
	)
}
