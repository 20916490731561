export default function TermsAndConditionsText() {
	return (
		<div className="flex-1 overflow-auto text-left [&_a]:typography-label-large-prominent [&_h2]:typography-title-medium [&_p]:typography-body-medium [&_a]:text-primary [&_a]:underline [&_h2]:py-2 [&_p]:py-2">
			<p>
				Please read these Terms of Service (the “Terms”) and our Privacy Policy (“Privacy Policy”) carefully because
				they govern your use of the website located at{' '}
				<a href="https://Payboticfinancial.com/">https://Payboticfinancial.com/</a> (the “Site”) and our ability to
				support your ability to make purchases with supported merchants via the Site offered by IPN B, LLC. d/b/a
				Paybotic Financial (“PF”). To make these Terms easier to read, the Site, and our services are collectively
				called the “Services”.
			</p>
			<p className="typography-label-medium-prominent">
				IMPORTANT NOTICE REGARDING ARBITRATION: WHEN YOU AGREE TO THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION)
				TO RESOLVE ANY DISPUTE BETWEEN YOU AND PF THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE
				REVIEW CAREFULLY “DISPUTE RESOLUTION” BELOW FOR DETAILS REGARDING ARBITRATION.
			</p>
			<p className="typography-label-large-prominent">
				These terms include, among other things, your authorization for debits from and to your bank account (as defined
				below) via the automated clearinghouse network (“ACH”).
			</p>
			<p>
				<span className="typography-label-large-prominent">Agreement to Terms.</span> Using our Services constitutes
				your express and unequivocal consent and agreement to the Terms. Each time you access and/or use the Services,
				you agree to be bound by these Terms. If you don’t agree to be bound by these Terms, do not use the Services.
			</p>
			<p>
				<span className="typography-label-large-prominent">Privacy Policy.</span> Please review our Privacy Policy,
				which also governs your use of the Services, for information on how we collect, use, and share your information.
			</p>
			<p>
				<span className="typography-label-large-prominent">Changes to these Terms or the Services.</span> We may update
				the Terms from time to time in our sole discretion. If we do, we’ll let you know by posting the updated Terms on
				the Site and/or may also send other communications. It’s important that you review the Terms whenever we update
				them or when you use the Services. If you continue to use the Services after we have posted updated Terms it
				means that you accept and agree to the changes. If you don’t agree to be bound by the changes, you may not use
				the Services anymore. Because our Services are evolving over time we may change or discontinue all or any part
				of the Services, at any time and without notice, at our sole discretion.
			</p>
			<h2>Who May Use the Services?</h2>
			<p>
				<span className="underline">Eligibility.</span> You must be permitted to open a bank account to use our
				Services. You may use the Services only if you can form a binding contract with PF. You agree to use the
				Services in compliance with these Terms and all applicable local, state, national, and international laws, rules
				and regulations. Any use or access to the Services by anyone under 18 years of age is strictly prohibited. The
				Services are not available to any Customers of the Services previously removed from the Services by PF, or who
				are otherwise ineligible to use the Services under applicable law.
			</p>
			<p>
				<span className="underline">Registration and Customer ID.</span> If you want to use certain features of the
				Services, you’ll have to create an account (“Account”). You can do this via the Site. Upon registration, you
				will create an account by validating your phone number and creating an access code to login to your Account
				(“Customer ID”). You are the only person authorized to use your Customer ID and access code and for maintaining
				the confidentiality of your Customer ID and access code. You shall not permit or allow other persons to have
				access to or use your Customer ID and access code. You are responsible for the use of the Services under your
				Customer ID. You are responsible for protecting the information on your computer such as by installing
				anti-virus software, updating your software, password protecting your files, and not permitting third party
				physical or electronic access to your computer or bank account.
			</p>
			<p>
				<span className="underline">Accuracy of Account Information.</span> It’s important that you provide us with
				accurate, complete and up-to-date information for your Account and you agree to update such information to keep
				it accurate, complete and up-to-date. If you don’t, we might have to suspend or terminate your Account. You
				agree that you won’t disclose your Account access code to anyone, and you’ll notify us immediately of any
				unauthorized use of your Account. You’re responsible for all activities that occur under your Account, whether
				or not you know about them.
			</p>
			<p>
				<span className="typography-label-large-prominent">Compliance Screening and Inquiries.</span> We may also ask
				questions and verify information ourselves or through trusted entities we work with for verification and
				compliance purposes. You agree that, consistent with our Privacy Policy, we may use the information you provide
				and/or other information to verify your identity. This may include looking up available public information and
				asking other entities, like banks or credit unions, for information about you. You acknowledge and agree that,
				consistent with our Privacy Policy, we may make any inquiries that we consider necessary, either directly or
				through third parties, concerning your identity and creditworthiness including, without limitation, requiring
				you to take steps to confirm ownership of your email address, phone number, or financial information, or
				verifying information against third party databases or through other sources.
			</p>
			<p>
				<span className="typography-label-large-prominent">Feedback.</span> We appreciate feedback, comments, ideas,
				proposals, and suggestions for improvements to the Services (“Feedback”). If you choose to submit Feedback, you
				agree that we are free to use it without any restriction or compensation to you. You grant to us a
				non-exclusive, transferable, worldwide, perpetual, irrevocable, fully paid, royalty-free license, with the right
				to sublicense, under any and all intellectual property rights that you own or control to use, copy, modify,
				create derivative works based upon and otherwise exploit the Feedback for any purpose.
			</p>
			<p>
				<span className="typography-label-large-prominent">PF Services.</span> Subject to the terms and conditions of
				these Terms, you are hereby granted a non-exclusive, limited, non-transferable, freely revocable license to use
				the Services as permitted by the features of the Services. PF reserves all rights not expressly granted herein
				in the Services and the PF Content (as defined below).
			</p>
			<p>
				(a) <span className="underline">Commercial Use.</span> You may only use the Services for your Commercial use and
				not on behalf of or for the benefit of any third party.
			</p>
			<p>
				(b) <span className="underline">General.</span> When you make a purchase via the on-line banking portal (a
				“Transaction”), you expressly authorize us to charge you for such Transaction. In addition to your Account
				information, we may request additional information to process your Transaction including information about your
				Bank Account as defined below, address, or other information necessary to initiate a Transaction (such
				information, “Transaction Information”). You represent and warrant that you have the legal right to use all
				payment method(s) represented by any such Transaction Information. You agree to be responsible for paying any
				applicable sales and use taxes, and any applicable shipping and handling fees that may apply to your purchase.
			</p>
			<p>
				(c) <span className="underline">Returns and Maintaining Sufficient Funds.</span> When using the Services, you
				understand and agree that it may take more than 60 days for PF to receive notice of the return or reversal of an
				ACH debit and/or to exercise any rights granted or reserved under these Terms. You agree at all times to
				maintain sufficient funds to satisfy all obligations in connection with your Account or your use of the
				Services, including payments, returns, disputes and associated fees, and to add funds immediately if PF notifies
				you that your funds are insufficient. PF is not responsible for overdraft or other fees that your financial
				institution may charge you for canceled, declined, or returned transactions or otherwise.
			</p>
			<p>
				(d) <span className="underline">Declined Payments and Collections.</span> During your access and/or use of the
				Services, you may encounter a declined payment. A declined payment may be the result of insufficient funds in
				your Bank Account, a closed Bank Account, inability to locate your Bank Account, a dispute, or a reversal or
				denial for any reason by your financial institution. If your payment is declined for any reason, you will be
				notified via email and/or phone call; and, provided PF has not identified that you have engaged in prohibited
				conduct, PF will (i) disable your account and (ii) attempt to process the Transaction again (a “Representment”)
				using any and all payment options that have been connected to your PF account. A successful Representment will
				resolve the declined payment. An unsuccessful Representment will result in your account to remain disabled until
				the payment is resolved. In that instance, PF may, in its sole and absolute discretion, also take one or more of
				the following actions: (i) refuse to perform further Services, (ii) initiate another Representment(s), (iii)
				charge you a penalty fee for each occurrence, (iv) report this information to any and all credit agencies and/or
				financial institutions, (v) immediately terminate this Agreement and your ability to use the Services; and/or
				(vi) engage a collection agency to pursue owed funds. If you request to remove your account while you have an
				outstanding declined payment(s), PF will not honor the request until the declined payment(s) is resolved.
			</p>
			<p>
				(e) <span className="underline">Merchant Purchases.</span>We facilitate your ability to make payments to third
				party merchants. We do not hold, transfer, disperse, or otherwise process funds on your behalf.
			</p>
			<p>
				(f) <span className="underline">Refunds and Reversals.</span>All refunds are processed by merchants unless
				otherwise required by law. PF is not responsible for making any refunds or reversing any payments. You
				understand that once a Transaction is processed, we are not able to reverse the Transaction through our Services
				or otherwise provide a refund in any way for the Transaction. If an ACH debit from your bank account to fund a
				payment requested by you through the Services is rejected or reversed for any reason, YOU AUTHORIZE PF TO
				REVERSE THE CORRESPONDING PAYMENT TO YOU VIA DEBIT FROM YOUR PAYMENT ACCOUNT, OR IF THERE ARE INSUFFICIENT FUNDS
				IN YOUR PAYMENT ACCOUNT AT THE TIME OF DEBIT, YOU AGREE TO PAY THE AMOUNT OF THE PAYMENT BACK TO PF WITHIN 5
				BUSINESS DAYS. You represent and warrant that PF may resubmit a rejected or reversed funding debit to the
				Customer’s account, in PF’s sole discretion. You also agree to pay all penalties, interest charges, late payment
				fees and service fees related to any rejected, reversed or returned funding ACH debit.
			</p>
			<p>
				<span className="typography-label-large-prominent">ACH Credit/Debit Authorization.</span> By agreeing to these
				Terms, you authorize PF to electronically debit your designated deposit account at your designated depository
				financial institution (your “Bank Account”) via ACH and, if ever applicable, to correct erroneous debits.
			</p>
			<p>
				(a) You acknowledge and agree that: (a) Transactions will go through ACH, (b) all Transactions are governed by
				ACH rules, and (c) your ACH transactions will comply with U.S. law. You can learn more about the ACH at{' '}
				<a href="https://www.nacha.org/">nacha.org.</a>
			</p>
			<p>
				(b) By using the Service, you authorize us to initiate electronic withdrawals from your bank account to fund
				purchases from designated merchants (“Transactions”) through electronic funding methods (“Debits”). Debits will
				also be initiated to pay for any sales, use or other taxes payable on merchant transactions, and for adjustments
				to these various amounts. You authorize us to direct your designated financial institution (“Bank Account”) to:
				(a) charge each Transaction and/or Debit to Bank Account and (b) respond to our inquiries regarding Bank
				Account. You agree that Bank Account is located in the United States, and that all Transactions will be to
				accounts located in the United States.
			</p>
			<p>
				(c) Your Agreement and Representations. For purposes of ACH debits and credits, and all electronic payments made
				or received through the Services, you: (i) authorize PF to initiate and process Debits in accordance with your
				payment instructions and as provided under these Terms; and (ii) agree to be subject to and comply with this
				Agreement, the Nacha Rules, and all other applicable laws, rules and regulations.
			</p>
			<p>
				(d) In addition to any of your other representations and warranties in these Terms, you represent that: (i) your
				browser is equipped with at least 128-bit security encryption; (ii) you are capable of printing, storing, or
				otherwise saving a copy of this electronic authorization for your records; and (iii) the ACH transactions you
				hereby authorize comply with applicable law.
			</p>
			<p>
				(e) [Bank Verification.] Prior to processing any Transaction, we may verify Bank Account information. The
				verification process may include sending you texts, voice calls, or automated/pre-recorded voice calls. If you
				provided your mobile phone number to us, you agree we may send such communications to that number. You agree
				that as part of the verification process we may: (i) verify Bank Account information by debiting between $0.01
				and $1.00 from Bank Account, then crediting an amount equal to or more than the same amount back to Bank
				Account, and requesting you to verify the amount debited and credited, and/or (ii) verify Bank Account using
				your login credentials to your financial institution and we may also ask you questions pertaining to your bank
				balance and/ or recent debit transactions. We will only use this verification process to screen for fraud and
				will not otherwise debit Bank Account, except for your use of Services. You hereby grant us a limited power of
				attorney to initiate the actions in this Section as part of the bank verification process.
			</p>
			<p>
				(f) You further authorize us to: (i) initiate transactions with Bank Account to collect Transactions,
				reinitiate, or initiate a new Debit to Bank Account if any Debit is returned for insufficient funds or
				uncollected funds, (ii) credit Bank Account when necessary, at our sole discretion, for any refund amount due to
				you, and/or (iii) to send Transactions, electronically or by any other commercially accepted method, to the
				appropriate financial institution(s). If PF tells you that an account number or other information concerning
				your Transactions has changed, you must use this corrected information in the future to initiate Transactions.
			</p>
			<p>
				(g) We may: (i) establish security limits on Transactions, such as a maximum number or dollar amount, (ii)
				change security limits from time to time without disclosing such changes, and (iii) refuse to process your
				Transactions if we reasonably believe Bank Account balance is insufficient to cover the amounts due or for any
				other reason we deem reasonable. We may in our sole discretion process any Transactions that have not been
				settled after these Terms are terminated.
			</p>
			<p>
				(h) This ACH authorization will remain in full force and effect until we receive your written notification of
				termination in such time and manner as to afford PF a reasonable opportunity to act on your termination notice.
			</p>
			<p>
				(i) If any amount payable by you is dishonored or returned for any reason, such as, but not limited to,
				non-sufficient funds, account closed, inability to locate account, or reversal by you and/or your bank, PF may:
				(i) reverse any corresponding credit issued to a merchant without liability to us, (ii) reverse a Transaction,
				(iii) refuse to perform further Services, (iv) initiate another debit in substitution for the dishonored debit
				until the debit is honored and to initiate separate debits to cover any NSF fee or dishonored Transaction, (v)
				charge you a one-time insufficient funds penalty fee for each occurrence, (vi) report this information to any
				and all credit agencies and/or financial institutions, and/or (viii) immediately terminate this Agreement.
			</p>
			<h2>Reinitiation of ACH Debit Entries </h2>
			<p>
				In accordance with the NACHA Operating Rules, effective September 18, 2015, the following guidelines apply to
				the reinitiation of ACH Debit Entries that have been returned:{' '}
			</p>
			<p className="typography-label-large-prominent">Conditions for Reinitiation: </p>
			<p>
				(a) Debit Entries returned due to insufficient or uncollected funds (Return Reason Codes R01 and R09) may be
				reinitiated up to two times following the initial return.
			</p>
			<p>
				(b) A Debit Entry may also be reinitiated if corrective action has been taken to address the reason for the
				return.
			</p>
			<p>
				(c) Debit Entries returned due to a stop payment may only be reinitiated if reinitiation has been separately
				authorized by the Account Holder after the Entry was returned.
			</p>
			<p className="typography-label-large-prominent">Prohibited Reinitiations:</p>
			<p>
				Entries returned as unauthorized or due to revocation or termination of authorization may not be reinitiated.
			</p>
			<p>Timeline for Reinitiations:</p>
			<p>(a) Reinitiations must occur within a 180-day window from the Settlement Date of the original Entry.</p>
			<p>
				(b)Within this 180-day window, Debit Entries returned for insufficient or uncollected funds may be reinitiated
				up to two additional times.
			</p>
			<p className="typography-label-large-prominent">Content Requirements for Reinitiated Entries: </p>
			<p>
				(a) The reinitiated Entry must contain identical content in the following fields: Company Name, Company ID, and
				Amount.
			</p>
			<p>
				(b) The description field for reinitiated Entries must include the words “RETRY PYMT” in all upper case letters.
			</p>
			<p>
				(c) By adhering to these guidelines, we ensure compliance with NACHA Operating Rules and maintain the integrity
				of our ACH transaction processes.
			</p>
			<p>
				<span className="typography-label-large-prominent">Transaction History.</span> PF will maintain a record of your
				Transaction(s) made using the Services for the previous two (2) years or such time as required by applicable
				law. You may access and view your Transactions History in the PF Site or by logging on to your Account via{' '}
				<a href="https://www.payboticfinancial.com">www.payboticfinancial.com</a>.
			</p>
			<h2>Your Content.</h2>
			<p>
				(a) <span className="underline">Posting Content.</span> Our Services may allow you to store or share content
				such as text (in posts or communications with others), files, documents, graphics, images, music, software,
				audio and video. Anything (other than Feedback) that you post or otherwise make available through the Services
				is referred to as “User Content”. PF does not claim any ownership rights in any User Content and nothing in
				these Terms will be deemed to restrict any rights that you may have to your User Content.
			</p>
			<p>
				(b) <span className="underline">Permissions to Your User Content.</span> By making any User Content available
				through the Services you hereby grant to PF a non-exclusive, transferable, worldwide, royalty-free license, with
				the right to sublicense, to use, copy, modify, create derivative works based upon, distribute, publicly display,
				and publicly perform your User Content in connection with operating and providing the Services.
			</p>
			<p>
				(c) <span className="underline">Your Responsibility for User Content.</span> You are solely responsible for all
				your User Content. You represent and warrant that you have (and will have) all rights that are necessary to
				grant us the license rights in your User Content under these Terms. You represent and warrant that neither your
				User Content, nor your use and provision of your User Content to be made available through the Services, nor any
				use of your User Content by PF on or through the Services will infringe, misappropriate, or violate a third
				party’s intellectual property rights, or rights of publicity or privacy, or result in the violation of any
				applicable law or regulation.
			</p>
			<p>
				(d) <span className="underline">Removal of User Content.</span> You can remove your User Content by specifically
				deleting it. You should know that in certain instances, some of your User Content (such as posts or comments you
				make) may not be completely removed and copies of your User Content may continue to exist on the Services. To
				the maximum extent permitted by law, we are not responsible or liable for the removal or deletion of (or the
				failure to remove or delete) any of your User Content.
			</p>
			<p>
				(e) <span className="underline">PF’s Intellectual Property.</span> We may make available through the Services
				content that is subject to intellectual property rights. We retain all rights to that content.
			</p>
			<h2>Rights and Terms for Apps.</h2>
			<p>
				(a) <span className="underline">App License</span> If you comply with these Terms, PF grants to you a limited
				non-exclusive, non-transferable license, with no right to sublicense, to download and install the App on your
				personal computers, mobile handsets, tablets, wearable devices, and/or other devices and to run the App solely
				for your own personal non-commercial purposes. Except as expressly permitted in these Terms, you may not: (i)
				copy, modify or create derivative works based on the App; (ii) distribute, transfer, sublicense, lease, lend or
				rent the App to any third party; (iii) reverse engineer, decompile or disassemble the App (unless applicable law
				permits, despite this limitation); or (iv) make the functionality of the App available to multiple users through
				any means.
			</p>
			<p>
				<span className="typography-label-large-prominent">Rules of Use.</span> You must always act responsibly and
				reasonably when accessing and/or using the Services. You may only access and/or use the Services and its
				contents for lawful purposes and in accordance with all applicable law. You are prohibited from accessing and/or
				using the Services and its contents for any unlawful purposes and/or in contravention of any applicable law. You
				are prohibited from uploading, storing, distributing, and/or transmitting any unlawful or inappropriate material
				to or through the Services. You may not upload, collect, store, distribute, or transmit personal information
				regarding other individuals to or through the Services. You recognize that uploading, storing, distributing, or
				transmitting unlawful or inappropriate material to or through the Services could expose you to criminal and/or
				civil liability. You agree that if a third-party claims that material you have uploaded, collected, stored,
				distributed, transmitted, or contributed to or through the Services is unlawful or inappropriate, you are solely
				responsible for that material and you will bear the burden of establishing that it is lawful and/or appropriate.
				You understand and agree that all material uploaded, stored, distributed, or transmitted to or through the
				Services is the sole responsibility of you, not PF, and that you are responsible for all material you upload,
				store, distribute, or otherwise transmit to or through the Services.
			</p>
			<p>
				Put simply, and in addition to any and all other limitations on damages, waivers, or releases – you agree that
				PF IS NOT IN ANY WAY RESPONSIBLE OR LIABLE FOR ANY UNLAWFUL OR INAPPROPRIATE MATERIAL UPLOADED, STORED,
				DISRIBUTED, OR TRANSMITTED TO OR THROUGH THE SERVICES BY OR ON BEHALF OF ANY OTHER INDIVIDUAL OR ENTITY.
				Furthermore, you voluntarily, knowingly, and unconditionally forever, permanently, fully, completely, and
				finally release, acquit, remise, and discharge PF, its current, past and future parents, subsidiaries,
				affiliates, predecessors, and successors, as well as each of their members, directors, officers, employees,
				agents, representatives, licensors, suppliers, service providers, shareholders, attorneys, and insurers, as
				applicable (the “PF Parties”), from any and all past, present or future actions, assessments, awards, causes of
				action, claims, complaints, controversies, counter-claims, cross-claims, damages (including, but not limited to,
				actual, statutory, compensatory, consequential, punitive, or other damages), demands, injuries (including, but
				not limited to bodily and personal injuries, including death), judgments, liabilities, liens, losses, penalties,
				property damage, sanctions, suits, and verdicts of any kind, character, or manner whatsoever, regardless of
				applicable law, in law (state or federal, statutory or common-law) or equity, contract, tort, or otherwise,
				whether contingent or fixed, direct or indirect, or derivative (collectively, “Claims”), in any way, directly or
				indirectly, relating to, arising from, resulting from, concerning, or caused by any (alleged) illegal or
				inappropriate material uploaded, stored, distributed, or transmitted to or through the Services by or on behalf
				of any other individual or entity.
			</p>
			<p>
				Your access and/or use of the Services is also contingent on your explicit and unconditional agreement not to
				engage in any of the following prohibited conduct:
			</p>
			<p>
				<span className="typography-label-large-prominent">Prohibitions and PF’s Enforcement Rights.</span> You agree
				not to do any of the following:
			</p>
			<p>
				(a) Post, upload, publish, submit or transmit any User Content that: (i) infringes, misappropriates or violates
				a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights,
				or rights of publicity or privacy; (ii) violates, or encourages any conduct that would violate, any applicable
				law or regulation or would give rise to civil liability; (iii) is fraudulent, false, misleading or deceptive;
				(iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes discrimination, bigotry, racism,
				hatred, harassment or harm against any individual or group; (vi) is violent or threatening or promotes violence
				or actions that are threatening to any person or entity; or (vii) promotes illegal or harmful activities or
				substances.
			</p>
			<p>
				(b) Use, display, mirror or frame the Services or any individual element within the Services, PF’s name, any PF
				trademark, logo or other proprietary information, or the layout and design of any page or form contained on a
				page, without PF’s express written consent.
			</p>
			<p>
				(c) Access, tamper with, or use non-public areas of the Services, PF’s computer systems, or the technical
				delivery systems of PF’s providers.
			</p>
			<p>
				(d) Attempt to probe, scan or test the vulnerability of any PF system or network or breach any security or
				authentication measures.
			</p>
			<p>
				(e) Avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent any technological measure
				implemented by PF or any of PF’s providers or any other third party (including another user) to protect the
				Services.
			</p>
			<p>
				(f) Attempt to access or search the Services or download content from the Services using any engine, software,
				tool, agent, device, or mechanism (including spiders, robots, crawlers, data mining tools or the like) other
				than the software and/or search agents provided by PF or other generally available third-party web browsers.
			</p>
			<p>
				(g) Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain
				letters or other form of solicitation.
			</p>
			<p>
				(h) Use any meta tags or other hidden text or metadata utilizing a PF trademark, logo URL or product name
				without PF’s express written consent.
			</p>
			<p>
				(i) Use the Services, or any portion thereof, for any commercial purpose or for the benefit of any third party
				or in any manner not permitted by these Terms.
			</p>
			<p>
				(j) Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or
				in any way use the Services to send altered, deceptive or false source-identifying information.
			</p>
			<p>
				(k) Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the
				Services.
			</p>
			<p>
				(l) Interfere with, or attempt to interfere with, the access of any user, host or network, including, without
				limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services.
			</p>
			<p>
				(m) Collect or store any personally identifiable information from the Services from other users of the Services
				without their express permission.
			</p>
			<p>(n) Impersonate or misrepresent your affiliation with any person or entity.</p>
			<p>(o) Violate any applicable law or regulation.</p>
			<p>(p) Encourage or enable any other individual to do any of the foregoing.</p>
			<p>
				PF is not obligated to monitor access to or use of the Services or to review or edit any content. However, we
				have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms and to
				comply with applicable law or other legal requirements. We reserve the right, but are not obligated, to remove
				or disable access to any content, including User Content, at any time and without notice, including, but not
				limited to, if we, at our sole discretion, consider it objectionable or in violation of these Terms. We have the
				right to investigate violations of these Terms or conduct that affects the Services. We may also consult and
				cooperate with law enforcement authorities to prosecute users who violate the law.
			</p>
			<p>
				<span className="typography-label-large-prominent">Links to Third Party Websites or Resources.</span> The
				Services may allow you to access third-party websites or other resources. We provide access only as a
				convenience and are not responsible for the content, products, or services on or available from those resources
				or links displayed on such websites. You acknowledge sole responsibility for and assume all risk arising from
				your use of any third-party resources.
			</p>
			<p>
				<span className="typography-label-large-prominent">Automated Decision-Making.</span> refers to a decision which
				is taken solely on the basis of automated processing of your personal data. This means processing using, for
				example, software code or an algorithm, which does not require human intervention.
			</p>
			<p>
				Subject to applicable law, and as further addressed in the Privacy Policy, we use artificial intelligence and/or
				machine learning software that assesses your perceived financial ability to pay to help us determine your
				eligibility to access and/or use the Services and what limits to place on your ability to transact on the
				Services. This information may negatively affect, restrict, or prevent you from using the Services. Depending on
				where you live, you may have certain rights to opt out of such automated decision-making processing. For more
				details on your rights, please see our Privacy Policy.
			</p>
			<p>
				<span className="typography-label-large-prominent">
					No Financial, Investment, Legal, Accounting, or Tax Advice.
				</span>{' '}
				The Services does not and should not be construed to constitute or provide any financial, investment, legal,
				accounting, tax, or other advice. To the contrary, PF does not provide you any financial, legal, accounting,
				tax, or other advice. You shall not rely upon PF or the Services, or otherwise access and/or use the Services,
				in connection with making or evaluating any financial, investment, legal, accounting, tax, or other decisions.
				We urge you to consult an experienced financial, investment, legal, accounting, or tax advisor with respect to
				any such issues. In addition, nothing available through the Services is an offer to sell, or a solicitation of
				any offer to buy, any financial products. Likewise, no content of the Services should be used as the basis of
				any investment or other financial decision.
			</p>
			<p>
				<span className="typography-label-large-prominent">Investigations.</span> PF reserves the right to investigate
				suspected violations of the Terms, including, without limitation, any suspected violation arising from any
				submission, posting, or e-mails you make or send. PF may seek to gather information from any user who is
				suspected of violating the Terms, and from any other individuals or entities as it alone deems necessary and
				appropriate. PF in its sole and absolute discretion may suspend any user whose conduct and/or postings are under
				investigation and may remove such material from its servers as it, in its sole and absolute discretion, deems
				appropriate and without notice.
			</p>
			<p>
				If PF believes, in its sole and absolute discretion, that a violation of the Terms has occurred, it may edit or
				modify any submission, posting, or e-mails, remove the material permanently from the Services, cancel postings,
				warn users, suspend users and/or passwords, disable or terminate accounts, and/or take other corrective action,
				including referral to law enforcement, that it, in its sole and absolute discretion, deems appropriate. As more
				fully addressed in the Privacy Policy, PF will fully cooperate with any law enforcement, regulatory, or other
				government authorities or court order requesting or directing PF to disclose the identity of anyone posting any
				e-mails, or publishing or otherwise making available any materials that are believed to violate the Terms, or
				for any other reason.
			</p>
			<p>
				By accessing and/or using the Services and accepting the Terms, you voluntarily, knowingly, and unconditionally
				forever, permanently, fully, completely, and finally release, acquit, remise, and discharge the PF Parties, from
				any and all Claims in any way, directly or indirectly, relating to, arising from, resulting from, concerning, or
				caused by any (alleged) investigation and/or any actions taken as a consequence of any investigation.
			</p>
			<p>
				<span className="typography-label-large-prominent">Termination.</span> We may suspend or terminate your access
				to and use of the Services, including suspending access to or terminating your account, at our sole discretion,
				at any time and without notice to you. You may cancel your account at any time by logging into your Account and
				doing it yourself or by sending us an email at support@payboticfinancial.com. Upon any termination,
				discontinuation or cancellation of the Services or your account, the following Sections will survive:
			</p>
			<p>
				<span className="typography-label-large-prominent">Warranty Disclaimers.</span> THE SERVICES AND SERVICES ARE
				MADE AVAILABLE TO YOU ON AN “AS IS,” “WHERE IS,” AND “WHERE AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY
				KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY. WITHOUT LIMITING THE FOREGOING, PF EXPLICITLY DISCLAIMS ALL
				WARRANTIES WITH RESPECT TO THE SERVICES, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
				PURPOSE, NON-INFRINGEMENT, AND TITLE. ALL DISCLAIMERS OF ANY KIND ARE MADE FOR THE BENEFIT OF THE PF PARTIES.
				WHILE WE TRY TO MAINTAIN THE INTEGRITY AND SECURITY OF THE SERVICES, WE DO NOT GUARANTEE THE SERVICES IS OR WILL
				REMAIN COMPLETE, CORRECT, OR SECURE, OR THAT ACCESS TO THE SERVICES WILL BE UNINTERRUPTED.{' '}
			</p>
			<p>
				Put simply, and in addition to any and all other limitations on damages, waivers, or releases, you agree that PF
				IS NOT IN ANY WAY RESPONSIBLE OR ANY FAILURE TO MAINTAIN THE INTEGRITY AND SECURITY OF THE SERVICES, OR TO KEEP
				THE SERVICES COMPLETE, CORRECT, OR SECURE, OR TO ENSURE UNINTERRUPTED ACCESS TO THE SERVICES. Furthermore, you
				voluntarily, knowingly, and unconditionally forever, permanently, fully, completely, and finally release,
				acquit, remise, and discharge the PF Parties from any and all Claims in any way, directly or indirectly,
				relating to, arising from, resulting from, concerning, or caused by any (alleged) failure (i) to maintain the
				integrity and/or security of the Services; (ii) to keep the Services complete, correct, or secure; and/or (iii)
				to ensure uninterrupted access to the Services.
			</p>
			<p>
				<span className="typography-label-large-prominent">Indemnity.</span> You will indemnify and hold PF and its
				officers, directors, employees and agents, harmless from and against any claims, disputes, demands, liabilities,
				damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees
				arising out of or in any way connected with (a) your access to or use of the Services, (b) your User Content, or
				(c) your violation of these Terms.
			</p>
			<h2>Limitation of Liability.</h2>
			<p>
				(a) <span className="underline">Limitation of Liability</span> IN NO EVENT SHALL PF BE LIABLE TO YOU, YOUR
				ORGANIZATION OR INDIVIDUAL, ANY USER, OR ANY THIRD PARTY IN CONNECTION WITH THIS AGREEMENT OR THE SERVICE, FOR
				ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOSS OF INCOME, DATA, PROFITS, REVENUE OR
				BUSINESS INTERRUPTION, OR COST OF SUBSTITUTE SERVICES, OR OTHER ECONOMIC LOSS, WHETHER OR NOT PF HAS BEEN
				ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND WHETHER ANY CLAIM FOR RECOVERY IS BASED ON THEORIES OF CONTRACT,
				WARRANTY, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY) OR OTHERWISE. PF WILL NOT BE LIABLE FOR ATTORNEYS’
				FEES, EXCEPT AS REQUIRED BY LAW.
			</p>
			<p>
				NOTWITHSTANDING ANY OTHER PROVISIONS OF THIS AGREEMENT, IN NO EVENT SHALL PF’S AGGREGATE LIABILITY TO YOU, YOUR
				ORGANIZATION OR INDIVIDUAL, ANY USER, OR ANY THIRD PARTY IN CONNECTION WITH THIS AGREEMENT OR THE SERVICE EXCEED
				THE TOTAL FEES PAID BY YOU OR YOUR ORGANIZATION OR INDIVIDUAL TO PF IN THE TWELVE MONTH PERIOD PRECEDING THE
				EVENT(S) THAT GAVE RISE TO SUCH LIABILITY, OR ONE HUNDRED USD ($100.00), WHICHEVER IS GREATER, REGARDLESS OF THE
				FORM OR THEORY OF THE CLAIM OR ACTION.
			</p>
			<p>
				Each provision of this Agreement that provides for a limitation of liability, disclaimer of warranties, or
				exclusion of damages is intended to and does allocate the risks between the parties under this Agreement. Each
				of these provisions is severable and independent of all other provisions of this Agreement. All limitations of
				liability, disclaimers of warranties, and exclusions of consequential damages or other damages or remedies will
				remain fully valid, effective and enforceable in accordance with their respective terms, even under
				circumstances that cause an exclusive remedy to fail of its essential purpose. SOME JURISDICTIONS DO NOT ALLOW
				THE EXCLUSION OR LIMITATION OF DAMAGES, LIABILITY OR CERTAIN WARRANTIES IN CERTAIN CIRCUMSTANCES. ACCORDINGLY,
				IN THOSE JURISDICTIONS, SOME OF THESE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY.
			</p>
			<p>
				<span className="typography-label-large-prominent">Governing Law and Forum Choice.</span> These Terms and any
				action related thereto will be governed by the Federal Arbitration Act, federal arbitration law, and the laws of
				the State of Washington, without regard to its conflict of laws provisions. Except as otherwise expressly set
				forth in “Dispute Resolution,” the exclusive jurisdiction for all Disputes (defined below) that you and PF are
				not required to arbitrate will be the state and federal courts located in the United States, and you and PF each
				waive any objection to jurisdiction and venue in such courts.
			</p>
			<h2>Dispute Resolution.</h2>
			<p>
				(a) <span className="underline">Mandatory Arbitration of Disputes.</span> We each agree that any dispute, claim
				or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation
				or validity thereof or the use of the Services (collectively, “Disputes”) will be resolved solely by binding,
				individual arbitration and not in a class, representative or consolidated action or proceeding. You and PF agree
				that the U.S. Federal Arbitration Act governs the interpretation and enforcement of these Terms, and that you
				and PF are each waiving the right to a trial by jury or to participate in a class action. This arbitration
				provision shall survive termination of these Terms.
			</p>
			<p>
				(b) <span className="underline">Exceptions.</span> As limited exceptions to Dispute Resolution above: (i) either
				party may seek to resolve a Dispute in small claims court if it qualifies; and (ii) we each retain the right to
				seek injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or
				misappropriation of our intellectual property rights.
			</p>
			<p>
				(c) <span className="underline">Conducting Arbitration and Arbitration Rules.</span>The arbitration will be
				conducted by the American Arbitration Association (“AAA”) under its Consumer Arbitration Rules (the “AAA Rules”)
				then in effect, except as modified by these Terms. The AAA Rules are available at{' '}
				<a href="http://www.adr.org/">adr.org</a> or by calling 1-800-778-7879. A party who wishes to start arbitration
				must submit a written Demand for Arbitration to AAA and give notice to the other party as specified in the AAA
				Rules. The AAA provides a form Demand for Arbitration at <a href="http://www.adr.org/">www.adr.org</a>.
			</p>
			<p>
				Any arbitration hearings will take place in the county (or parish) where you live, unless we both agree to a
				different location. The parties agree that the arbitrator shall have exclusive authority to decide all issues
				relating to the interpretation, applicability, enforceability and scope of this arbitration agreement.
			</p>
			<p>
				(d) <span className="underline">Arbitration Costs.</span> Transaction of all filing, administration and
				arbitrator fees will be governed by the AAA Rules, and we won’t seek to recover the administration and
				arbitrator fees we are responsible for paying, unless the arbitrator finds your Dispute frivolous. If we prevail
				in arbitration, we’ll pay all of our attorneys’ fees and costs and won’t seek to recover them from you. If you
				prevail in arbitration you will be entitled to an award of attorneys’ fees and expenses to the extent provided
				under applicable law.
			</p>
			<p>
				(e) <span className="underline">Injunctive and Declaratory Relief.</span> Except as provided in Dispute
				Resolution above, the arbitrator shall determine all issues of liability on the merits of any claim asserted by
				either party and may award declaratory or injunctive relief only in favor of the individual party seeking relief
				and only to the extent necessary to provide relief warranted by that party’s individual claim. To the extent
				that you or we prevail on a claim and seek public injunctive relief (that is, injunctive relief that has the
				primary purpose and effect of prohibiting unlawful acts that threaten future injury to the public), the
				entitlement to and extent of such relief must be litigated in a civil court of competent jurisdiction and not in
				arbitration. The parties agree that litigation of any issues of public injunctive relief shall be stayed pending
				the outcome of the merits of any individual claims in arbitration.
			</p>
			<p>
				(f) <span className="underline">Class Action Waiver.</span> YOU AND PF AGREE THAT EACH MAY BRING CLAIMS AGAINST
				THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
				OR REPRESENTATIVE PROCEEDING. Further, if the parties’ dispute is resolved through arbitration, the arbitrator
				may not consolidate another person’s claims with your claims, and may not otherwise preside over any form of a
				representative or class proceeding. If this specific provision is found to be unenforceable, then the entirety
				of this Dispute Resolution section shall be null and void.
			</p>
			<p>
				(g) <span className="underline">Severability.</span> With the exception of any of the provisions in Dispute
				Resolution of these Terms (“Class Action Waiver”), if an arbitrator or court of competent jurisdiction decides
				that any part of these Terms is invalid or unenforceable, the other parts of these Terms will still apply.
			</p>
			<h2>General Terms.</h2>
			<p>
				(a) <span className="underline">Reservation of Rights.</span> PF and its licensors exclusively own all rights,
				title and interest in and to the Services, including all associated intellectual property rights. You
				acknowledge that the Services are protected by copyright, trademark, and other laws of the United States and
				foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other
				proprietary rights notices incorporated in or accompanying the Services.
			</p>
			<p>
				(b) <span className="underline">Entire Agreement.</span> These Terms constitute the entire and exclusive
				understanding and agreement between PF and you regarding the Services, and these Terms supersede and replace all
				prior oral or written understandings or agreements between PF and you regarding the Services. If any provision
				of these Terms is held invalid or unenforceable by an arbitrator or a court of competent jurisdiction, that
				provision will be enforced to the maximum extent permissible, and the other provisions of these Terms will
				remain in full force and effect. You may not assign or transfer these Terms, by operation of law or otherwise,
				without PF’s prior written consent. Any attempt by you to assign or transfer these Terms, without such consent,
				will be null. PF may freely assign or transfer these Terms without restriction. Subject to the foregoing, these
				Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.
			</p>
			<p>
				(c) <span className="underline">Notices.</span> Any notices or other communications provided by PF under these
				Terms will be given: (i) via email; or (ii) by posting to the Services. For notices made by email, the date of
				receipt will be deemed the date on which such notice is transmitted.
			</p>
			<p>
				(d) <span className="underline">Waiver of Rights.</span> PF’s failure to enforce any right or provision of these
				Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will
				be effective only if in writing and signed by a duly authorized representative of PF. Except as expressly set
				forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without
				prejudice to its other remedies under these Terms or otherwise.
			</p>
			<p>
				<span className="typography-label-large-prominent">Contact Information.</span> If you have any questions about
				these Terms or the Services, please contact{' '}
				<a href="mailto:info@payboticfinancial.com">info@payboticfinancial.com</a> or 1-833-420-7300
			</p>
		</div>
	)
}
