import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Auth0Role, BusinessUser } from '@/api/hooks/use-business-user-by-auth-id'
import { useUpdateBURole } from '@/api/hooks/use-user-update-role'
import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Form,
	FormComponent,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui'
import { getNameFromAuthRoles, getNameFromRole, getRoleLabel, MyRole, roleMappings } from '@/constants/my-role.ts'

type Props = {
	open: boolean
	onOpenChange: (open: boolean) => void
	username: string
	currentRole: Auth0Role[]
	selectedUser: BusinessUser | null
}

const formSchema = z.object({
	role: z.string().min(1, { message: 'Role required' }),
})

export function ChangeRoleDialog({ open, onOpenChange, username, currentRole, selectedUser }: Props) {
	const { mutate: updateBURole, isPending: isLoadingRole } = useUpdateBURole()
	const initialRole = useMemo(() => getNameFromAuthRoles(currentRole), [currentRole])
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			role: initialRole,
		},
	})

	function onSubmit(values: z.infer<typeof formSchema>) {
		if (!selectedUser) return
		updateBURole({ buAuth0Id: selectedUser?.Auth0ID, role: values.role })
	}

	return (
		<Dialog
			open={open}
			onOpenChange={onOpenChange}
		>
			<DialogContent>
				<DialogHeader>
					<DialogTitle className="mb-4 text-center">
						<p className="typography-title-large">Change User Role</p>
					</DialogTitle>
					<DialogDescription className="text-center">
						<p>
							Select the new role for user
							<span className="ml-1 font-bold text-black">{username}</span>
						</p>
					</DialogDescription>
				</DialogHeader>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="space-y-8"
					>
						<FormComponent
							control={form.control}
							name="role"
							label="Role"
							render={({ field }) => (
								<Select
									onValueChange={field.onChange}
									defaultValue={field.value}
									value={field.value}
									name={field.name}
								>
									<SelectTrigger>
										<SelectValue placeholder="Select a role" />
									</SelectTrigger>
									<SelectContent>
										{Array.from(roleMappings.keys())
											.filter((role) => role !== MyRole.Consumer)
											.map((role) => (
												<SelectItem
													key={role}
													value={getNameFromRole(role)}
												>
													{getRoleLabel(role)}
												</SelectItem>
											))}
									</SelectContent>
								</Select>
							)}
						/>
						<DialogFooter>
							<DialogClose asChild>
								<Button
									className="w-full"
									type="button"
									variant="outline"
								>
									Cancel
								</Button>
							</DialogClose>
							<Button
								disabled={isLoadingRole}
								className="w-full"
								type="submit"
							>
								Save
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	)
}
