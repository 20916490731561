import { useAuth0 } from '@auth0/auth0-react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { DateInput } from '@/components/inputs/date-input.tsx'
import { PhoneInput } from '@/components/inputs/phone-input.tsx'
import {
	Form,
	FormControl,
	FormItem,
	FormLabel,
	FormMessage,
	useFormComponentControlled,
} from '@/components/ui/form/form.tsx'
import { Input } from '@/components/ui/index.ts'
import { UserSchema } from '@/utils/validations.tsx'
import { RegistrationFormLayout } from './RegistrationFormLayout.tsx'
import { RegistrationLayout } from './RegistrationLayout.tsx'

const personalInformationSchema = UserSchema.pick({
	first_name: true,
	last_name: true,
	date_of_birth: true,
	phone_number: true,
})

type PersonalInformationFormData = z.infer<typeof personalInformationSchema>

export type PersonalInformationFormProps = {
	defaultValues?: Partial<PersonalInformationFormData>
	onSubmit: (data: PersonalInformationFormData) => void
}

export default function PersonalInformationForm({ defaultValues, onSubmit }: PersonalInformationFormProps) {
	const { user } = useAuth0()

	const form = useForm<PersonalInformationFormData>({ defaultValues, resolver: zodResolver(personalInformationSchema) })
	const FormComponent = useFormComponentControlled(form.control)

	return (
		<RegistrationLayout
			title="Personal Information"
			description="Add your personal information to get started."
		>
			<Form {...form}>
				<RegistrationFormLayout
					isSubmitting={form.formState.isSubmitting}
					onSubmit={form.handleSubmit(onSubmit)}
				>
					<FormItem>
						<FormLabel>Email</FormLabel>
						<FormControl>
							<Input
								readOnly
								disabled
								type="email"
								value={user?.email}
							/>
						</FormControl>
						<FormMessage />
					</FormItem>

					<FormComponent
						required
						name="first_name"
						label="First Name"
						render={({ field }) => (
							<Input
								{...field}
								placeholder="First Name"
							/>
						)}
					/>

					<FormComponent
						required
						name="last_name"
						label="Last Name"
						render={({ field }) => (
							<Input
								{...field}
								placeholder="Last Name"
							/>
						)}
					/>

					<FormComponent
						required
						name="date_of_birth"
						label="Birthday"
						render={({ field }) => <DateInput {...field} />}
					/>

					<FormComponent
						required
						name="phone_number"
						label="Phone Number"
						render={({ field }) => <PhoneInput {...field} />}
					/>
				</RegistrationFormLayout>
			</Form>
		</RegistrationLayout>
	)
}
