import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { usePurchase } from '@/api/hooks/use-purchase'
import { Loading } from '@/components/loading'
import { PaymentQr } from '@/pages/consumer/payment/payment-qr-page'
import { useConsumerPurchaseStore } from '@/store/consumer/consumerPurchaseStore'
import { usePurchaseWebSocket } from '../../../api/hooks/use-purchase-websocket'

export function PaymentPage() {
	const navigate = useNavigate()
	const setConfirmationStatus = useConsumerPurchaseStore((state) => state.setConfirmationStatus)

	const { addPurchase, isPending, isSuccess, isError, purchase, qrCodeExpired } = usePurchase()
	const isLoading = isPending || (!isSuccess && !isError)

	usePurchaseWebSocket(purchase?.ID, (purchaseWebsocketStatus) => {
		setConfirmationStatus(purchaseWebsocketStatus)
		navigate('/payment/confirmation')
	})

	useEffect(() => {
		addPurchase()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (isLoading) {
		return <Loading />
	}

	return (
		<div
			className="absolute bottom-0 left-0 right-0 top-0 flex h-screen w-screen items-center justify-center"
			data-testid="qr-code-container"
		>
			<div className="flex h-screen w-screen flex-col items-center justify-center bg-blue-linear p-6">
				<PaymentQr
					purchase={purchase}
					qrCodeExpired={qrCodeExpired}
					addPurchase={addPurchase}
				/>
			</div>
		</div>
	)
}
