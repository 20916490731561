import React from 'react'

import { isNumeric } from '@/utils/validations'
import { Input, InputProps } from '../ui'

const PhoneInput = React.forwardRef<HTMLInputElement, InputProps>(({ onChange, onKeyDown, ...props }, ref) => {
	const handleKeyDown = React.useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>, passedOnKeyDown?: typeof onKeyDown) => {
			if (['Backspace'].includes(e.key)) {
				const phoneNumber = e.currentTarget.value
				let numberOfDigitsToDelete = 0
				while (numberOfDigitsToDelete < phoneNumber.length) {
					numberOfDigitsToDelete++
					const start = phoneNumber.length - numberOfDigitsToDelete - 1
					const currentChar = phoneNumber.substring(start, start + 1)
					if (isNumeric(currentChar)) {
						break
					}
				}
				if (numberOfDigitsToDelete > 1) {
					e.currentTarget.value = phoneNumber.substring(0, phoneNumber.length - numberOfDigitsToDelete)
					e.preventDefault()
				}
			}
			passedOnKeyDown?.(e)
		},
		[],
	)

	const handleOnChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, passedOnChange?: typeof onChange) => {
			const value = e.target.value
			// Remove all non-digit characters
			const numbers = value.replace(/\D/g, '')

			// Apply formatting
			let phoneNumber = ''
			for (let i = 0; i < numbers.length && i < 10; i++) {
				switch (i) {
					case 0:
						phoneNumber += '(' + numbers[i]
						break
					case 2:
						phoneNumber += numbers[i] + ') '
						break
					case 5:
						phoneNumber += numbers[i] + '-'
						break
					default:
						phoneNumber += numbers[i]
				}
			}

			// Update the input value using the provided onChange callback
			e.target.value = phoneNumber
			passedOnChange?.(e)
		},
		[],
	)

	return (
		<Input
			placeholder="(000) 000-0000"
			type="tel"
			inputMode="numeric"
			{...props}
			ref={ref}
			onChange={(e) => handleOnChange(e, onChange)}
			onKeyDown={(e) => handleKeyDown(e, onKeyDown)}
		/>
	)
})
PhoneInput.displayName = 'PhoneInput'

export { PhoneInput }
