import { ArrowRight } from 'lucide-react'
import { Link } from 'react-router-dom'

type SettingsItemProps = {
	href: string
	title: string
	subtitle?: string
	testId?: string
}

export const SettingsItem = ({ href, title, subtitle, testId }: SettingsItemProps) => {
	return (
		<Link
			to={href}
			className="flex h-[72px] cursor-pointer items-center justify-between border-b border-b-neutral-500 px-sm py-md align-middle hover:bg-neutral-100"
			data-testid={`settings-item-${testId || title.toLowerCase().replace(/\s+/g, '-')}`}
		>
			<div className="flex flex-col">
				<span className="text-body-large">{title}</span>
				{subtitle && <span className="text-label-medium text-neutral-900">{subtitle}</span>}
			</div>

			<ArrowRight className="text-2xl" />
		</Link>
	)
}
