import { XIcon } from 'lucide-react'
import { Link, useSearchParams } from 'react-router-dom'

import { useUser } from '@/api/hooks/use-user'
import { ErrorDisplay } from '@/components/error-display'
import { Loading } from '@/components/loading'
import { Button } from '@/components/ui/'

export function LinkAccountErrorPage() {
	const [searchParams] = useSearchParams()
	const { isLoading: queryRunning } = useUser()

	if (queryRunning) {
		return <Loading />
	}

	return (
		<div className="m-auto flex h-full max-w-lg flex-col p-4 text-center">
			<Button
				asChild
				variant="secondary"
				size="icon"
				className="ml-auto"
			>
				<Link
					replace
					to={searchParams.get('returnTo') || '/dashboard/accounts'}
				>
					<XIcon />
				</Link>
			</Button>

			<ErrorDisplay
				description="That’s not an eligible account. Please ensure you are using a checking account and try again."
				className="my-auto"
			/>

			<div className="space-y-2">
				<Button
					asChild
					variant="destructive"
					className="w-full"
				>
					<Link
						replace
						to="/link-account"
					>
						Try Again
					</Link>
				</Button>

				<Button
					asChild
					variant="link"
					className="w-full"
				>
					<Link
						replace
						to="/dashboard"
					>
						Back To Home
					</Link>
				</Button>
			</div>
		</div>
	)
}
