import { ArrowLeftIcon, HandCoinsIcon, LandmarkIcon, QrCodeIcon, StoreIcon } from 'lucide-react'
import { Link, useSearchParams } from 'react-router-dom'

import { usePlaidToken } from '@/api/hooks/use-plaid-token'
import { useUser } from '@/api/hooks/use-user'
import { Loading } from '@/components/loading'
import { Button, Dialog, DialogContent, DialogTrigger } from '@/components/ui'
import { cn } from '@/lib/utils.ts'

const steps = [
	{ text: 'Link a Checking Account with Plaid', icon: LandmarkIcon },
	{ text: 'Visit a Participating Merchant', icon: StoreIcon },
	{ text: 'Use the “Pay Now” Button on your Home Screen to checkout at the register', icon: HandCoinsIcon },
	{ text: 'The Merchant will scan the QR code for a quick & easy checkout', icon: QrCodeIcon },
]

export function LinkAccountPage() {
	const [searchParams] = useSearchParams()
	const { isLoading: queryRunning, error: userError, data: user } = useUser()
	const {
		open,
		ready,
		token,
		error,
		loading: plaidLoading,
		linkTokenError,
	} = usePlaidToken({
		redirectTo: searchParams.get('redirectTo'),
		returnTo: user?.has_created_external_account ? searchParams.get('returnTo') : '/dashboard',
		vendor_access_token: searchParams.get('vendorAccessToken'),
		vendor_institution_id: searchParams.get('vendorInstitutionID'),
	})

	if (error || userError || linkTokenError) {
		return <div>An error occurred: {error?.message || userError?.message || linkTokenError?.message}</div>
	}

	if (queryRunning || plaidLoading || !token) {
		return <Loading />
	}

	return (
		<div className="m-auto flex h-full max-w-lg flex-col p-4 md:justify-center">
			<Button
				asChild
				variant="secondary"
				size="icon"
				className="rounded-full"
			>
				<Link to={searchParams.get('returnTo') || '/dashboard/accounts'}>
					<ArrowLeftIcon />
				</Link>
			</Button>

			<h1 className="typography-headline-medium pt-6 text-primary md:mb-12">How It Works</h1>

			<ol className="m-auto overflow-auto px-4 py-6 md:m-0">
				{steps.map(({ text, icon: Icon }, i) => (
					<li
						key={text}
						className="flex space-x-4"
					>
						<div className="flex flex-col items-center pt-2">
							<div className="h-2 w-2 flex-none rounded-full bg-tertiary" />
							<div className="h-full border-l border-tertiary" />
						</div>
						<div className={cn('space-y-2', i < steps.length - 1 && 'pb-6')}>
							<Icon className="text-primary-300" />
							<p className="typography-body-large">{text}</p>
						</div>
					</li>
				))}
			</ol>

			<div className="space-y-2 md:mt-12">
				<Button
					data-testid="link-account-button"
					className="w-full"
					onClick={() => open()}
					disabled={!ready}
				>
					Link Checking Account
				</Button>
				<Dialog>
					<DialogTrigger asChild>
						<Button
							variant="link"
							className="w-full"
						>
							Watch Video
						</Button>
					</DialogTrigger>
					<DialogContent className="h-2/5 overflow-hidden p-0 [&>button]:hidden">
						<iframe
							className="h-full w-full border-0"
							src="https://www.youtube.com/embed/QfkFue9Een0"
							title="Paybotic Tutorial Video"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerPolicy="strict-origin-when-cross-origin"
							allowFullScreen
						></iframe>
					</DialogContent>
				</Dialog>
			</div>
		</div>
	)
}
