import { useQuery } from '@tanstack/react-query'
import { HTTPError, KyInstance } from 'ky'

import { usePaymentService } from '@/api/services/providers/payment-service-provider.tsx'
import { MyRole } from '@/constants/my-role.ts'
import { Nullable } from 'src/@types/nullable'
import { usePermissions } from 'src/hooks/use-permissions.ts'
import { usePermissionsStore } from 'src/store/permissions-store.ts'

export type Auth0Role = {
	id: string
	name: MyRole
	description: string
}

export type BusinessUser = {
	ID: number
	BusinessID: number
	Auth0ID: string
	SynPersonID: string
	Email: string
	Username: string
	Roles: Auth0Role[]
	CreatedAt: Date
	UpdatedAt: Date
	BusinessName: string
}

export async function getBusinessUser(httpInstance: KyInstance) {
	try {
		const response = await httpInstance.get('businesses/user')
		return response.json<BusinessUser>()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}

export const useBusinessUserByAuthId = () => {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()
	const { isLoading: isLoadingPermissions } = usePermissions()
	const isConsumer = usePermissionsStore((state) => state.isConsumer)
	return useQuery<Nullable<BusinessUser>, Error, Nullable<BusinessUser>>({
		queryKey: ['BusinessUserByAuthId'],
		queryFn: () => getBusinessUser(paymentServiceClient.httpInstance),
		enabled: !isLoading && isAuthenticated && !isLoadingPermissions && !isConsumer,
		staleTime: 600000, // 10 minutes
	})
}
