import { Link } from 'react-router-dom'

import { ErrorDisplay } from '@/components/error-display'
import { Button } from '@/components/ui'

export function ErrorPage() {
	return (
		<div className="m-auto flex h-full max-w-lg flex-col p-4 text-center md:justify-center md:gap-8">
			<ErrorDisplay
				description="Something went wrong. Please contact Paybotic Financial directly."
				className="my-auto md:my-0"
			/>

			<div className="space-y-2">
				<Button
					asChild
					variant="destructive"
					className="w-full"
				>
					<Link to="/logout">Re-Authenticate</Link>
				</Button>

				<Button
					asChild
					variant="link"
					className="block w-full"
				>
					<a href="mailto:support@payboticfinancial.com">
						Need Help? <span className="underline">Contact Us.</span>
					</a>
				</Button>
			</div>
		</div>
	)
}
