import React from 'react'

import { Input, InputProps } from '../ui'

const CurrencyInput = React.forwardRef<HTMLInputElement, InputProps>(({ onChange, value, ...props }, ref) => {
	const handleOnChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, passedOnChange?: typeof onChange) => {
			const value = e.target.value
			// Remove all non-digit characters
			e.target.value = value.replace(/\D/g, '')
			// Update the input value using the provided onChange callback
			passedOnChange?.(e)
		},
		[],
	)

	const valueNumber = Number(value)

	return (
		<Input
			placeholder={(0).toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
			})}
			inputMode="numeric"
			{...props}
			ref={ref}
			onChange={(e) => handleOnChange(e, onChange)}
			value={
				valueNumber !== 0
					? (valueNumber / 100).toLocaleString('en-US', {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: 2,
						})
					: ''
			}
		/>
	)
})
CurrencyInput.displayName = 'CurrencyInput'

export { CurrencyInput }
