import { Card } from '@/components/ui'
import { DashboardLayout } from '@/modules/consumer/dashboard/dashboard-layout'
import { TransactionsList } from '@/modules/consumer/dashboard/TransactionsList'
import { useInfiniteTransactions } from '../../../api/hooks/use-transaction'

export function TransactionHistory() {
	const { data, isLoading, error, fetchNextPage, hasNextPage } = useInfiniteTransactions()

	const transactions = data?.pages.flatMap((page) => page.items) || []

	if (error) {
		return (
			<DashboardLayout>
				<Card className="p-4">
					<h5 className="typography-headline-medium text-primary-500">Failed to load transactions</h5>
				</Card>
			</DashboardLayout>
		)
	}
	if (!isLoading && transactions.length === 0) {
		return (
			<DashboardLayout>
				<Card className="p-4">
					<h5 className="typography-headline-medium text-primary-500">This user has no transactions</h5>
				</Card>
			</DashboardLayout>
		)
	}

	return (
		<DashboardLayout>
			<div data-testid="TransactionHistory">
				<h5 className="typography-headline-medium mb-4 text-primary-500">All Transactions</h5>

				<TransactionsList
					data={transactions}
					isLoading={isLoading}
					showSeeMoreButton={hasNextPage}
					seeMoreOnClick={() => fetchNextPage()}
				/>
			</div>
		</DashboardLayout>
	)
}
