import LogoBlue from '@/assets/images/logo-blue.svg'

export function Header() {
	return (
		<header className="fixed top-0 z-100 w-full bg-white">
			<div className="flex h-header items-center justify-center py-3">
				<img
					src={LogoBlue}
					alt="Paybotic logo"
					className="h-full w-auto"
				/>
			</div>
		</header>
	)
}
