import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import {
	Form,
	FormControl,
	FormItem,
	FormLabel,
	FormMessage,
	useFormFieldControlled,
} from '@/components/ui/form/form.tsx'
import { Checkbox } from '@/components/ui/index.ts'
import { UserSchema } from '@/utils/validations.tsx'
import { RegistrationFormLayout } from './RegistrationFormLayout.tsx'
import { RegistrationLayout } from './RegistrationLayout.tsx'
import TermsAndConditionsText from './TermsAndConditionsText.tsx'

const termsAndConditionsSchema = UserSchema.pick({
	terms_accepted: true,
})

type TermsAndConditionsFormData = z.infer<typeof termsAndConditionsSchema>

export type TermsAndConditionsFormProps = {
	defaultValues?: Partial<TermsAndConditionsFormData>
	onSubmit: (data: TermsAndConditionsFormData) => void
}

export default function TermsAndConditionsForm({ defaultValues, onSubmit }: TermsAndConditionsFormProps) {
	const form = useForm<TermsAndConditionsFormData>({ defaultValues, resolver: zodResolver(termsAndConditionsSchema) })
	const FormField = useFormFieldControlled(form.control)

	return (
		<RegistrationLayout title="Terms and Conditions">
			<div className="flex min-h-0 flex-1 flex-col">
				<div className="min-h-0 flex-1 overflow-auto">
					<TermsAndConditionsText />
				</div>

				<Form {...form}>
					<RegistrationFormLayout
						isSubmitting={form.formState.isSubmitting}
						onSubmit={form.handleSubmit(onSubmit)}
						className="h-auto pt-6"
					>
						<FormField
							name="terms_accepted"
							render={({ field }) => (
								<FormItem className="text-center">
									<div className="flex items-center justify-center space-x-2">
										<FormControl>
											<Checkbox
												ref={field.ref}
												checked={field.value}
												onCheckedChange={field.onChange}
											/>
										</FormControl>
										<FormLabel>I accept the terms and conditions</FormLabel>
									</div>
									<FormMessage />
								</FormItem>
							)}
						/>
					</RegistrationFormLayout>
				</Form>
			</div>
		</RegistrationLayout>
	)
}
