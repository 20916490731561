import { z } from 'zod'
import { create } from 'zustand'

import { PurchaseResponse, PurchaseWebsocketStatus } from '@/@types/purchase'

export const purchaseInfoSchema = z.object({
	price: z.coerce.number().min(1, { message: 'Price must be at least $0.01' }),
	employeeName: z.string().min(1, { message: 'Employee name is required' }),
	posOrderId: z.string().optional(),
	notes: z.string().optional(),
})

export type PurchaseInfo = z.infer<typeof purchaseInfoSchema>

export type MerchantCheckoutStore = {
	purchase: PurchaseInfo | null
	purchaseResponse: PurchaseResponse | null
	confirmationResponse: PurchaseWebsocketStatus | null
	error: string | null
	setPurchase: (purchase: PurchaseInfo | null) => void
	setPurchaseResponse: (response: PurchaseResponse | null) => void
	setConfirmationResponse: (response: PurchaseWebsocketStatus | null) => void
	setError: (error: string | null) => void
	reset: () => void
}

export const useMerchantCheckoutStore = create<MerchantCheckoutStore>((set) => ({
	purchase: null,
	purchaseResponse: null,
	confirmationResponse: null,
	error: null,
	setPurchase: (purchase) => set({ purchase }),
	setPurchaseResponse: (purchaseResponse) => set({ purchaseResponse }),
	setConfirmationResponse: (confirmationResponse) => set({ confirmationResponse }),
	setError: (error) => set({ error }),
	reset: () => set({ purchase: null, purchaseResponse: null, confirmationResponse: null, error: null }),
}))
