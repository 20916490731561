import { useState } from 'react'

import { Button, InputOTP, InputOTPGroup, InputOTPSlot } from '@/components/ui'

type Props = {
	callback: (paymentCode: string) => void
}

export const CheckoutEnterCode = ({ callback }: Props) => {
	const [paymentCode, setPaymentCode] = useState('')
	const [error, setError] = useState(false) // State to track error

	const handleInputChange = (value: string) => {
		setPaymentCode(value)
		error && setError(false)
	}

	const onSubmit = () => {
		if (paymentCode.length === 5) {
			callback(paymentCode)
		} else {
			setError(true)
		}
	}

	return (
		<div className="mx-auto flex max-w-md flex-col items-center justify-center gap-8 p-4">
			<p className="typography-body-large">Enter the 5-digit code on the customer’s phone.</p>
			<InputOTP
				maxLength={5}
				onChange={handleInputChange}
			>
				<InputOTPGroup>
					<InputOTPSlot
						index={0}
						className={error ? 'border-red-500' : ''}
					/>
					<InputOTPSlot
						index={1}
						className={error ? 'border-red-500' : ''}
					/>
					<InputOTPSlot
						index={2}
						className={error ? 'border-red-500' : ''}
					/>
					<InputOTPSlot
						index={3}
						className={error ? 'border-red-500' : ''}
					/>
					<InputOTPSlot
						index={4}
						className={error ? 'border-red-500' : ''}
					/>
				</InputOTPGroup>
			</InputOTP>
			{error && <p className="text-sm text-red-500">Please enter a valid 5-digit code.</p>}
			<Button
				className="w-full"
				onClick={onSubmit}
			>
				Submit
			</Button>
		</div>
	)
}
