import { useBusinessUserByAuthId } from '@/api/hooks/use-business-user-by-auth-id.ts'
import { Loading } from '@/components/loading/index.ts'
import { MyPermissions } from '@/constants/my-permissions.ts'
import { AboutRolesDialog } from '@/modules/merchant/users/about-roles.tsx'
import { AddUserDialog } from '@/modules/merchant/users/add-user.tsx'
import { usePermissionsStore } from '@/store/permissions-store.ts'
import { BusinessUsersTable } from '../../modules/merchant/users/bu-table.tsx'

export const ListBusinessUsersPage = () => {
	const { data: businessUser, isLoading } = useBusinessUserByAuthId()
	const permissions = usePermissionsStore((state) => state.permissions)
	const createPersonEnabled = permissions.includes(MyPermissions.CreateBusinessUsers)

	if (isLoading || !businessUser) {
		return <Loading />
	}

	return (
		<div
			className="container flex w-full flex-col gap-md p-4 xl:p-8"
			data-testid="ListBusinessUsersPage"
		>
			<div className="flex items-center justify-between">
				<h2 className="typography-headline-medium text-primary">Users</h2>
				<div className="flex items-center space-x-2">
					<div className="hidden sm:block">
						<AboutRolesDialog />
					</div>
					{createPersonEnabled && <AddUserDialog />}
				</div>
			</div>
			<BusinessUsersTable businessId={businessUser.BusinessID} />
		</div>
	)
}
