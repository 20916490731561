import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { BusinessUser } from '@/api/hooks/use-business-user-by-auth-id'
import { useUpdateBUPassword } from '@/api/hooks/use-user-set-password'
import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
} from '@/components/ui'

type Props = {
	open: boolean
	onOpenChange: (open: boolean) => void
	username: string
	selectedUser: BusinessUser | null
}

const formSchema = z.object({
	password: z.string().min(1, { message: 'Role required' }),
})

export function ChangePasswordDialog({ open, onOpenChange, username, selectedUser }: Props) {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			password: '',
		},
	})
	const setInitialState = () => {
		form.reset()
		onOpenChange(false)
	}
	const { mutate: updateBUPassword, isPending: isLoadingPassword } = useUpdateBUPassword(setInitialState)

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		if (!selectedUser) return
		updateBUPassword({ buAuth0Id: selectedUser?.Auth0ID, password: values.password })
	}

	return (
		<Dialog
			open={open}
			onOpenChange={onOpenChange}
		>
			<DialogContent>
				<DialogHeader>
					<DialogTitle className="mb-4 text-center">
						<p className="typography-title-large">Change Password</p>
					</DialogTitle>
					<DialogDescription className="text-center">
						<p>
							Change the new password for user
							<span className="ml-1 font-bold text-black">{username}</span>
						</p>
					</DialogDescription>
				</DialogHeader>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						className="space-y-8"
					>
						<FormField
							control={form.control}
							name="password"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Password:</FormLabel>
									<FormControl>
										<Input
											placeholder="Enter a new password"
											type="password"
											{...field}
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<DialogFooter>
							<DialogClose asChild>
								<Button
									className="w-full"
									type="button"
									variant="outline"
								>
									Cancel
								</Button>
							</DialogClose>
							<Button
								disabled={isLoadingPassword}
								className="w-full"
								type="submit"
							>
								Save
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	)
}
