import { ChevronDownIcon, LogOutIcon, MenuIcon, StoreIcon, UserIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

import { useBusinessUserByAuthId } from '@/api/hooks/use-business-user-by-auth-id'
import LogoBlue from '@/assets/images/logo-blue.svg'
import LogoIconBlue from '@/assets/images/logo-icon-blue.svg'
import {
	Avatar,
	AvatarFallback,
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
	Skeleton,
	useSidebar,
} from '@/components/ui'
import { cn } from '@/lib/utils'

export function MerchantHeader() {
	const { isMobile, toggleSidebar } = useSidebar()
	return (
		<header className="fixed top-0 z-100 flex h-header w-full items-center justify-between bg-white px-3 py-4 sm:px-6">
			{isMobile ? (
				<div className="flex items-center gap-2">
					<Button
						variant="secondary"
						size="icon"
						onClick={toggleSidebar}
						className="bg-transparent"
					>
						<MenuIcon />
					</Button>
					<img
						src={LogoIconBlue}
						alt="Paybotic logo"
						className="h-9 w-9"
					/>
				</div>
			) : (
				<img
					src={LogoBlue}
					alt="Paybotic logo"
					className="w-[150px]"
				/>
			)}

			<MerchantDropdownMenu />
		</header>
	)
}

function MerchantDropdownMenu() {
	const navigate = useNavigate()
	const user = useBusinessUserByAuthId()

	const itemBaseClass = cn('typography-label-large flex items-center gap-2 p-3')

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="outline">
					{user.isPending ? (
						<>
							<Skeleton className="size-6 rounded-full bg-primary-100" />
							<Skeleton className="h-full w-20 bg-primary-100" />
						</>
					) : (
						<>
							<Avatar className="size-6">
								<AvatarFallback className="bg-primary-900 text-white">
									{user.data?.Email[0].toLocaleUpperCase()}
								</AvatarFallback>
							</Avatar>
							{user.data?.BusinessName}
						</>
					)}

					<ChevronDownIcon />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				className="w-72 px-0 py-1"
				align="end"
			>
				<DropdownMenuLabel className={itemBaseClass}>
					<UserIcon className="h-4 w-4" />
					{user.data?.Email}
				</DropdownMenuLabel>
				<DropdownMenuSeparator />
				{[user.data?.BusinessName].filter(Boolean).map((business) => (
					<DropdownMenuItem
						key={business}
						className={cn(itemBaseClass, 'rounded-none', true && 'bg-primary-50 text-primary-500')}
					>
						<StoreIcon className="h-4 w-4" />
						{business}
					</DropdownMenuItem>
				))}
				<DropdownMenuSeparator />
				<DropdownMenuItem
					className={cn(itemBaseClass, 'text-neutral-900')}
					onClick={() => navigate('/logout')}
				>
					<LogOutIcon /> Sign out
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
