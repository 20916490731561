import { useMutation, useQueryClient } from '@tanstack/react-query'
import { KyInstance } from 'ky'

import { BusinessUser } from '@/api/hooks/use-business-user-by-auth-id'
import { usePaymentService } from '@/api/services/providers/payment-service-provider'
import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui'
import { useAlert } from '@/lib/useAlert.tsx'

type Props = {
	open: boolean
	onOpenChange: (open: boolean) => void
	username: string
	selectedUser: BusinessUser
}

const deleteRecord = async (httpInstance: KyInstance, id: number) => {
	await httpInstance.delete(`businesses/user/${id}`)
}

export function DeleteUserDialog({ open, onOpenChange, username, selectedUser }: Props) {
	const { paymentServiceClient } = usePaymentService()
	const queryClient = useQueryClient()
	const { triggerSnackbar } = useAlert()

	const mutation = useMutation<void, Error, number>({
		mutationFn: async () => {
			return deleteRecord(paymentServiceClient.httpInstance, selectedUser?.ID)
		},
		onSuccess: async () => {
			queryClient.setQueryData<BusinessUser[]>(['business-users'], (oldData) => {
				return oldData?.filter((bu) => bu.ID !== selectedUser?.ID)
			})
			onOpenChange(false)
			triggerSnackbar('Business user deleted successfully', 'success')
		},
		onError: (error) => {
			console.error(error)
			onOpenChange(false)
			triggerSnackbar('Failed to delete business user', 'error')
		},
	})

	const handleDelete = () => {
		mutation.mutate(selectedUser?.ID)
	}

	return (
		<Dialog
			open={open}
			onOpenChange={onOpenChange}
		>
			<DialogContent>
				<DialogHeader>
					<DialogTitle className="mb-4 text-center">
						<p className="typography-title-large">Are You Sure?</p>
					</DialogTitle>
					<DialogDescription className="text-center">
						Are you sure you want to delete the user
						<span className="ml-1 font-bold text-black">{username} ?</span>
					</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<DialogClose asChild>
						<Button
							className="w-full"
							type="button"
							variant="outline"
						>
							Cancel
						</Button>
					</DialogClose>
					<Button
						onClick={handleDelete}
						variant="destructive"
						disabled={mutation.isPending}
						className="w-full"
						type="button"
					>
						Delete
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
