import { Table } from '@tanstack/react-table'

import {
	Pagination,
	PaginationButton,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationNext,
	PaginationPrevious,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui'
import { cn } from '@/lib/utils'

type DataTablePaginationProps<TData> = {
	table: Table<TData>
	className?: string
}

export function DataTablePagination<TData>({ table, className }: DataTablePaginationProps<TData>) {
	const currentPage = table.getState().pagination.pageIndex + 1
	const totalPages = table.getPageCount()

	const pageButtons = Array.from({ length: 3 }).reduce<number[]>((acc) => {
		const previousPage =
			acc.at(-1) || Math.max(currentPage === 1 ? 0 : currentPage === totalPages ? currentPage - 3 : currentPage - 2, 0)

		const nextPage = previousPage + 1

		if (nextPage > 0 && nextPage <= totalPages && nextPage !== previousPage) {
			acc.push(nextPage)
		}
		return acc
	}, [])

	return (
		<div className={cn('flex items-center justify-between px-2', className)}>
			<div className="flex flex-1 flex-wrap items-center justify-between gap-4">
				<div className="flex flex-1 items-center justify-between space-x-2 sm:justify-start">
					<p className="whitespace-nowrap text-sm font-medium">Rows per page</p>
					<Select
						value={`${table.getState().pagination.pageSize}`}
						onValueChange={(value) => {
							table.setPageSize(Number(value))
						}}
					>
						<SelectTrigger className="h-8 w-[70px]">
							<SelectValue placeholder={table.getState().pagination.pageSize} />
						</SelectTrigger>
						<SelectContent side="top">
							{[10, 20, 30, 40, 50].map((pageSize) => (
								<SelectItem
									key={pageSize}
									value={`${pageSize}`}
								>
									{pageSize}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>

				<Pagination className="w-auto">
					<PaginationContent>
						<PaginationItem>
							<PaginationPrevious
								onClick={() => table.previousPage()}
								disabled={!table.getCanPreviousPage()}
							/>
						</PaginationItem>
						<PaginationItem className={cn((pageButtons.length === 0 || pageButtons.at(0) === 1) && 'hidden')}>
							<PaginationEllipsis />
						</PaginationItem>
						{pageButtons.map((page) => (
							<PaginationItem key={page}>
								<PaginationButton
									isActive={page === currentPage}
									onClick={() => table.setPageIndex(page - 1)}
								>
									{page}
								</PaginationButton>
							</PaginationItem>
						))}
						<PaginationItem className={cn((pageButtons.length === 0 || pageButtons.at(-1) === totalPages) && 'hidden')}>
							<PaginationEllipsis className={cn(pageButtons.at(-1) === totalPages && 'invisible')} />
						</PaginationItem>

						<PaginationItem>
							<PaginationNext
								onClick={() => table.nextPage()}
								disabled={!table.getCanNextPage()}
							/>
						</PaginationItem>
					</PaginationContent>
				</Pagination>
			</div>
		</div>
	)
}
