import { Link, Navigate } from 'react-router-dom'

import success from '@/assets/images/success.svg'
import { OrderDetails } from '@/components/order-details.tsx'
import { Button } from '@/components/ui'
import { useMerchantCheckoutStore } from '@/store/merchant/merchantCheckoutStore'
import { dateFormatter } from '@/utils/dates'
import { formatCentsToCurrency } from '@/utils/money-utils'

export function MerchantCheckoutSuccess() {
	const purchaseResponse = useMerchantCheckoutStore((state) => state.purchaseResponse)
	const confirmationResponse = useMerchantCheckoutStore((state) => state.confirmationResponse)
	const reset = useMerchantCheckoutStore((state) => state.reset)

	if (!purchaseResponse || confirmationResponse?.status !== 'COMPLETED') {
		return <Navigate to="/merchant/checkout" />
	}

	return (
		<div className="h-full bg-neutral-50">
			<div className="container flex h-full w-full max-w-screen-sm flex-col items-center justify-center p-4 md:px-10 md:py-7">
				<img
					src={success}
					alt=""
					className="mb-2 size-20"
				/>
				<h1 className="typography-display-medium py-2 text-success">Success!</h1>
				<p className="typography-body-large text-center text-primary-900">
					The transaction has been submitted. Please view the Home page to check the status of the transaction.
				</p>

				<OrderDetails
					className="mt-8"
					storeName={purchaseResponse?.facility_name || ''}
					transactionDate={dateFormatter.format(
						purchaseResponse?.purchase_date ? new Date(purchaseResponse?.purchase_date) : new Date(),
					)}
					posOrderId={purchaseResponse?.pos_order_id}
					totalPrice={formatCentsToCurrency(purchaseResponse?.amount)}
					notes={purchaseResponse?.notes}
					customerName={purchaseResponse?.consumer_name}
					transactionId={purchaseResponse?.public_id}
				/>

				<Button
					asChild
					className="mt-auto w-full md:mt-8"
					variant="success"
				>
					<Link
						to="/merchant/checkout"
						onClick={() => reset()}
					>
						Back To Checkout
					</Link>
				</Button>
			</div>
		</div>
	)
}
