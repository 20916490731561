import { Check, ChevronsUpDown } from 'lucide-react'
import { useState } from 'react'

import {
	Button,
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui'
import { cn } from '@/lib/utils'

type ComboboxProps = {
	options: {
		value: string
		label: string
	}[]
	value: string
	onValueChange: (value: string) => void
}

export function Combobox({ options, value, onValueChange }: ComboboxProps) {
	const [open, setOpen] = useState(false)

	return (
		<Popover
			open={open}
			onOpenChange={setOpen}
		>
			<PopoverTrigger asChild>
				<Button
					disableClickAnimation
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className="typography-body-large w-full justify-between bg-white"
				>
					{value ? options.find((option) => option.value === value)?.label : 'Select state...'}
					<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-full p-0">
				<Command>
					<CommandInput placeholder="Search state..." />
					<CommandList>
						<CommandEmpty>No state found.</CommandEmpty>
						<CommandGroup>
							{options.map((option) => (
								<CommandItem
									key={option.value}
									value={option.label}
									onSelect={(currentLabel) => {
										const selectedOption = options.find((opt) => opt.label === currentLabel)
										const newValue = selectedOption?.value === value ? '' : selectedOption?.value || ''
										onValueChange(newValue)
										setOpen(false)
									}}
								>
									<Check className={cn('mr-2 h-4 w-4', value === option.value ? 'opacity-100' : 'opacity-0')} />
									{option.label}
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	)
}
