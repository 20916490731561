import { Row } from '@tanstack/react-table'
import { ChevronRightIcon } from 'lucide-react'

import { Button, ButtonProps } from '@/components/ui'
import { cn } from '@/lib/utils'

type DataTableColumnExpandButtonProps<TData> = {
	row: Row<TData>
} & ButtonProps

export function DataTableColumnExpandButton<TData>({
	row,
	className,
	...props
}: DataTableColumnExpandButtonProps<TData>) {
	if (!row.getCanExpand()) {
		return null
	}

	return (
		<Button
			size="icon"
			variant="link"
			onClick={row.getToggleExpandedHandler()}
			className={cn('transform transition-all', row.getIsExpanded() && 'rotate-90', className)}
			{...props}
		>
			<ChevronRightIcon />
		</Button>
	)
}
