import { Outlet } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'

import { SidebarProvider } from '@/components/ui/sidebar'
import { MerchantHeader } from './MerchantHeader'
import { MerchantNavigation } from './MerchantNavigation'

export function MerchantLayout() {
	const expandedNavigation = useMediaQuery(`(min-width: 1200px)`)
	return (
		<SidebarProvider open={expandedNavigation}>
			<MerchantHeader />
			<div className="flex w-full">
				<MerchantNavigation />
				<main
					data-testid="MerchantApp"
					className="min-w-0 flex-1 pt-header"
				>
					<Outlet />
				</main>
			</div>
		</SidebarProvider>
	)
}
