import { UseMutateFunction } from '@tanstack/react-query'
import { RotateCw, X } from 'lucide-react'
import { QRCodeSVG } from 'qrcode.react'
import { useNavigate } from 'react-router-dom'

import { InitialPurchaseResponse } from '@/api/hooks/use-purchase'
import Logo from '@/assets/images/p-logo.png'
import { Button, Card, CardContent, CardHeader } from '@/components/ui'

type PaymentQRProps = {
	purchase?: InitialPurchaseResponse
	qrCodeExpired: boolean
	addPurchase: UseMutateFunction<InitialPurchaseResponse>
}

export const PaymentQr = ({ purchase, qrCodeExpired, addPurchase }: PaymentQRProps) => {
	const navigate = useNavigate()

	const goToDashboard = () => {
		navigate('/dashboard')
	}

	return (
		<>
			<Card className="mb-4 mt-auto w-full max-w-md rounded-2xl">
				<CardHeader className="rounded-t-2xl bg-primary-50 text-center">
					<span className="typography-title-large text-primary">Show Merchant QR Code</span>
				</CardHeader>
				<CardContent className="w-full p-4">
					{purchase?.ID && (
						<QRCodeSVG
							includeMargin
							className="h-full w-full"
							value={`${location.protocol}//${location.host}/merchant/payment/${purchase.ID}`}
							size={235}
							level="H"
							imageSettings={{
								src: Logo,
								height: 50,
								width: 50,
								excavate: true,
							}}
						/>
					)}
					{qrCodeExpired && (
						<div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-30 backdrop-blur-md">
							<div
								onClick={() => addPurchase()}
								className="flex h-28 w-28 cursor-pointer flex-col items-center justify-center rounded-full bg-white bg-opacity-90"
							>
								<RotateCw className="h-16 w-16 text-[#003366]" />
								<span className="mt-[-4px] text-base font-bold text-[#003366]">Refresh</span>
							</div>
						</div>
					)}
				</CardContent>
			</Card>
			<Card className="flex w-full max-w-80 flex-col items-center justify-center rounded-2xl bg-primary-50 p-md text-primary">
				<span className="text-label-large">Payment Code:</span>
				<span
					className="typography-title-large text-center"
					data-testid="payment-code"
				>
					{purchase?.PublicId.substring(purchase?.PublicId.length - 5)}
				</span>
			</Card>
			<Button
				onClick={goToDashboard}
				className="mt-auto"
				type="button"
				variant="secondary"
				size="icon"
			>
				<X />
			</Button>
		</>
	)
}
