import { Link, Navigate } from 'react-router-dom'

import { ErrorDisplay } from '@/components/error-display'
import { Button } from '@/components/ui'
import { useMerchantCheckoutStore } from '@/store/merchant/merchantCheckoutStore'

export function MerchantCheckoutError() {
	const error = useMerchantCheckoutStore((state) => state.error)
	const reset = useMerchantCheckoutStore((state) => state.reset)

	if (!error) {
		return <Navigate to="/merchant/checkout" />
	}

	return (
		<div className="h-full bg-neutral-50">
			<div className="container flex h-full w-full max-w-screen-sm flex-col items-center justify-center p-4 md:px-10 md:py-7">
				<ErrorDisplay description="The transaction couldn’t be submitted. Please try again." />

				<Button
					asChild
					className="mt-auto w-full md:mt-8"
					variant="destructive"
				>
					<Link to="/merchant/checkout">Try Again</Link>
				</Button>
				<Button
					asChild
					className="mt-2 w-full"
					variant="link"
				>
					<Link
						to="/merchant/checkout"
						onClick={() => reset()}
					>
						Back To Checkout
					</Link>
				</Button>
			</div>
		</div>
	)
}
