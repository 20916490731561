import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { useBusinessUserByAuthId } from '@/api/hooks/use-business-user-by-auth-id.ts'
import { useSupport } from '@/api/hooks/use-support.ts'
import { PhoneInput } from '@/components/inputs/phone-input'
import { Loading } from '@/components/loading'
import {
	Button,
	Card,
	CardContent,
	Form,
	FormComponent,
	FormControl,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	Textarea,
} from '@/components/ui'

const formSchema = z.object({
	issue: z.string().min(1, { message: 'Issue is required' }),
	notes: z.string().min(10, 'We need more information'),
	phoneNumber: z.string().min(10, 'Please enter a valid phone number'),
})

type FormValues = z.infer<typeof formSchema>

export function MerchantSupportPage() {
	const navigate = useNavigate()
	const { mutate: submitSupport, isPending } = useSupport(() => navigate('/merchant'))
	const { data: businessUser, isLoading } = useBusinessUserByAuthId()
	const storeName = useMemo(() => businessUser?.BusinessName, [businessUser])

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			issue: '',
			notes: '',
			phoneNumber: '',
		},
	})

	const onSubmit = (data: FormValues) => {
		submitSupport({ ...data, storeName })
	}

	if (isLoading) {
		return <Loading />
	}

	return (
		<div className="container flex flex-col gap-md p-4 xl:p-8">
			<h1 className="typography-headline-medium text-primary">Support</h1>
			<p className="typography-body-medium">
				Share some details about the issue you're facing, and we'll get back to you as soon as we can. Thank you!
			</p>

			<Form {...form}>
				<form
					onSubmit={form.handleSubmit(onSubmit)}
					className="space-y-6"
				>
					<Card className="p-md shadow-none">
						<CardContent className="flex flex-col gap-md p-0">
							<FormComponent
								control={form.control}
								name="issue"
								label="Issue category"
								render={({ field }) => (
									<Select
										onValueChange={field.onChange}
										defaultValue={field.value}
										name={field.name}
									>
										<FormControl>
											<SelectTrigger>
												<SelectValue placeholder="Select a category" />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											<SelectItem value="Account / Setup">Account / Setup</SelectItem>
											<SelectItem value="Transaction">Transaction</SelectItem>
											<SelectItem value="Technical support">Technical support</SelectItem>
											<SelectItem value="Billing and invoicing">Billing and invoicing</SelectItem>
											<SelectItem value="Product and service">Product and service</SelectItem>
											<SelectItem value="Customer support">Customer support</SelectItem>
											<SelectItem value="Other">Other</SelectItem>
										</SelectContent>
									</Select>
								)}
							/>

							<FormComponent
								control={form.control}
								name="notes"
								label="Details about the issue"
								render={({ field }) => (
									<Textarea
										placeholder="Tell us more about your issue"
										className="min-h-[120px]"
										{...field}
									/>
								)}
							/>

							<FormComponent
								control={form.control}
								name="phoneNumber"
								label="Phone number"
								render={({ field }) => <PhoneInput {...field} />}
							/>
						</CardContent>
					</Card>
					<div className="flex w-full">
						<Button
							disabled={isPending}
							className="w-full"
							type="submit"
						>
							Submit
						</Button>
					</div>
				</form>
			</Form>
		</div>
	)
}
