import { X } from 'lucide-react'
import { useSnackbar } from 'notistack'

import { Button } from '@/components/ui'

export type SnackbarVariant = 'error' | 'default' | 'success' | 'warning' | 'info' | undefined

export type SnackbarTrigger = (message: string, variant?: SnackbarVariant) => void

export const useAlert = () => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const triggerSnackbar: SnackbarTrigger = (message = '', variant: SnackbarVariant = 'error') => {
		enqueueSnackbar(message, {
			variant: variant,
			autoHideDuration: 4000,
			action: (key) => (
				<Button
					size={'icon'}
					variant={'ghost'}
					className="rounded-full"
					aria-label="close"
					onClick={() => {
						closeSnackbar(key)
					}}
				>
					<X />
				</Button>
			),
		})
	}

	return { triggerSnackbar }
}
