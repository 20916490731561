import { useEffect, useState } from 'react'

export const useIsMobile = () => {
	const [isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		const checkIsMobile = () => {
			const mobileRegex = /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i

			const isMobile = mobileRegex.test(navigator.userAgent)

			// Special handling for iPads (especially for iPadOS 13+)
			const isIpad =
				navigator.userAgent.includes('iPad') || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

			setIsMobile(isMobile || isIpad)
		}

		checkIsMobile()

		window.addEventListener('resize', checkIsMobile)

		return () => window.removeEventListener('resize', checkIsMobile)
	}, [])

	return { isMobile }
}
