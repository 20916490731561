import { useCallback } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import { usePurchaseWebSocket } from '@/api/hooks/use-purchase-websocket'
import { LoadingIcon } from '@/components/loading/loading-icon.tsx'
import { OrderDetails } from '@/components/order-details.tsx'
import { Button } from '@/components/ui'
import { useMerchantCheckoutStore } from '@/store/merchant/merchantCheckoutStore'
import { dateFormatter } from '@/utils/dates'
import { formatCentsToCurrency } from '@/utils/money-utils'
import { PurchaseWebsocketStatus } from 'src/@types/purchase'

export function MerchantCheckoutAwaiting() {
	const navigate = useNavigate()
	const purchaseResponse = useMerchantCheckoutStore((state) => state.purchaseResponse)
	const setConfirmationResponse = useMerchantCheckoutStore((state) => state.setConfirmationResponse)
	const setError = useMerchantCheckoutStore((state) => state.setError)
	const reset = useMerchantCheckoutStore((state) => state.reset)

	const onWebhook = useCallback(
		(purchaseWebsocketStatus: PurchaseWebsocketStatus) => {
			setConfirmationResponse(purchaseWebsocketStatus)

			switch (purchaseWebsocketStatus.status) {
				case 'COMPLETED':
					navigate('/merchant/checkout/success')
					return
				case 'DENIED':
					navigate('/merchant/checkout/cancelled')
					return
				default:
					setError('Error')
					navigate('/merchant/checkout/error')
					break
			}
		},
		[navigate, setConfirmationResponse, setError],
	)

	usePurchaseWebSocket(purchaseResponse?.id, onWebhook)

	// this is needed to mimic the QR webhook message in the E2E test
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	;(window as any).onWebhook = onWebhook

	if (!purchaseResponse) {
		return <Navigate to="/merchant/checkout" />
	}

	return (
		<div className="h-full bg-neutral-50">
			<div className="container flex h-full w-full max-w-screen-sm flex-col items-center justify-center gap-2xl p-4 md:px-10 md:py-7">
				<div className="mt-16 flex flex-col items-center justify-center gap-md sm:mt-32">
					<LoadingIcon />
					<h1 className="typography-display-small text-center">Awaiting Customer Approval...</h1>
				</div>
				<OrderDetails
					storeName={purchaseResponse?.facility_name || ''}
					transactionDate={dateFormatter.format(
						purchaseResponse?.purchase_date ? new Date(purchaseResponse?.purchase_date) : new Date(),
					)}
					posOrderId={purchaseResponse?.pos_order_id}
					totalPrice={formatCentsToCurrency(purchaseResponse?.amount)}
					notes={purchaseResponse?.notes}
					customerName={purchaseResponse?.consumer_name}
					transactionId={purchaseResponse?.public_id}
				/>
				<Button
					asChild
					className="mt-auto w-full md:mt-8"
				>
					<Link
						to="/merchant/checkout"
						onClick={() => reset()}
					>
						Back To Checkout
					</Link>
				</Button>
			</div>
		</div>
	)
}
