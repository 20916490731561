import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'
import { KyInstance, KyResponse } from 'ky'

import { SnackbarTrigger, useAlert } from 'src/lib/useAlert.tsx'
import {
	PartialEditUserAddress,
	PartialEditUserInformation,
	PartialEditUserName,
	PartialEditUserPhone,
	User,
} from 'src/models/user.models.ts'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

const updateUserQueryClient = (queryClient: QueryClient, data: Partial<User>) => {
	const user = queryClient.getQueryData(['user']) as User
	queryClient.setQueryData(['user'], {
		...user,
		...data,
	})
}

const updateUserName = async (httpInstance: KyInstance, data: PartialEditUserName) =>
	httpInstance.patch('users/self/name', {
		json: data,
	})

const updateUserPhone = async (httpInstance: KyInstance, data: PartialEditUserPhone) =>
	httpInstance.patch('users/self/phone', {
		json: data,
	})

const updateUserAddress = async (httpInstance: KyInstance, data: PartialEditUserAddress['address']) =>
	httpInstance.patch('users/self/address', {
		json: data,
	})

const updateUserInformation = async (httpInstance: KyInstance, data: Partial<User>) =>
	httpInstance.patch('users/self/info', {
		json: data,
	})

const onSuccess = (queryClient: QueryClient, triggerSnackbar: SnackbarTrigger, data: Partial<User>) => {
	updateUserQueryClient(queryClient, data)
	triggerSnackbar('Personal details updated', 'success')
}

const onError = (triggerSnackbar: SnackbarTrigger, error: unknown) => {
	console.error(error)
	triggerSnackbar('Personal details update failed', 'error')
}

export function useUpdateUserName() {
	const { paymentServiceClient } = usePaymentService()
	const { triggerSnackbar } = useAlert()
	const queryClient = useQueryClient()

	return useMutation<KyResponse, Error, PartialEditUserName, void>({
		mutationFn: async (data) => updateUserName(paymentServiceClient.httpInstance, data),
		onSuccess: (_, data) => onSuccess(queryClient, triggerSnackbar, data),
		onError: (error) => onError(triggerSnackbar, error),
	})
}

export function useUpdateUserPhone() {
	const { paymentServiceClient } = usePaymentService()
	const { triggerSnackbar } = useAlert()
	const queryClient = useQueryClient()

	return useMutation<KyResponse, Error, PartialEditUserPhone, void>({
		mutationFn: async (data) => updateUserPhone(paymentServiceClient.httpInstance, data),
		onSuccess: (_, data) => onSuccess(queryClient, triggerSnackbar, data),
		onError: (error) => onError(triggerSnackbar, error),
	})
}

export function useUpdateUserAddress() {
	const { paymentServiceClient } = usePaymentService()
	const { triggerSnackbar } = useAlert()
	const queryClient = useQueryClient()

	return useMutation<KyResponse, Error, PartialEditUserAddress, void>({
		mutationFn: async (data) => updateUserAddress(paymentServiceClient.httpInstance, data.address),
		onSuccess: (_, data) => {
			const user = queryClient.getQueryData(['user']) as User
			const address = {
				...user.address,
				...data.address,
			}
			onSuccess(queryClient, triggerSnackbar, { address })
		},
		onError: (error) => onError(triggerSnackbar, error),
	})
}

export function useUpdateUserInformation() {
	const { paymentServiceClient } = usePaymentService()
	const { triggerSnackbar } = useAlert()
	const queryClient = useQueryClient()

	return useMutation<KyResponse, Error, PartialEditUserInformation, void>({
		mutationFn: async (data) => updateUserInformation(paymentServiceClient.httpInstance, data),
		onSuccess: (_, data) => {
			queryClient.invalidateQueries({
				queryKey: ['user'],
			})
			return onSuccess(queryClient, triggerSnackbar, data)
		},
		onError: (error) => onError(triggerSnackbar, error),
	})
}
