import { Link } from 'react-router-dom'

import { ErrorDisplay } from '@/components/error-display'
import { Button } from '@/components/ui'

export const PaymentFail = () => {
	return (
		<div className="m-auto flex h-full max-w-lg flex-col p-4 text-center">
			<ErrorDisplay
				description="Your transaction didn’t go through, Please try again."
				className="my-auto"
			/>

			<div className="space-y-2">
				<Button
					asChild
					variant="destructive"
					className="w-full"
				>
					<Link to="/dashboard">Back To Home</Link>
				</Button>
			</div>
		</div>
	)
}
