import { Transaction as TransactionProps } from '@/api/hooks/use-transaction.ts'
import { cn } from '@/lib/utils.ts'
import { formatCentsToCurrency } from '@/utils/money-utils'

type Props = TransactionProps & {
	displayDivider?: boolean
}

export function Transaction({ amount, purchase_date, business_name, last_four, status, public_id }: Props) {
	return (
		<div
			className="grid h-20 grid-cols-[1fr_auto] border-t border-t-primary-100"
			data-testid={`transaction-${public_id}-item`}
		>
			<div className="flex flex-col justify-center">
				<span
					className="text-xs font-bold text-neutral-800"
					data-testid={`transaction-${public_id}-date`}
				>
					{new Date(purchase_date).toDateString()}
				</span>
				<span
					className="text-sm font-bold text-primary-900"
					data-testid={`transaction-${public_id}-business`}
				>
					{business_name}
				</span>
				<div className="flex items-end text-neutral-800">
					<span className="text-xs">Account No.</span>
					<span
						className="ml-1 text-xs font-bold"
						data-testid={`transaction-${public_id}-account`}
					>
						x{last_four}
					</span>
				</div>
			</div>
			<div className="flex flex-col items-end justify-center text-right">
				<span
					className={cn('typography-body-large font-bold', status === 'Pending' && 'text-neutral-500')}
					data-testid={`transaction-${public_id}-amount`}
				>
					{formatCentsToCurrency(amount)}
				</span>
				<span
					className={cn('typography-label-medium-prominent font-bold', status === 'Pending' && 'text-neutral-500')}
					data-testid={`transaction-${public_id}-status`}
				>
					{status}
				</span>
			</div>
		</div>
	)
}
