import { useAuth0 } from '@auth0/auth0-react'

import { Button } from '@/components/ui'
import IconWhite from '../assets/images/landing-logo.png'

export default function LandingPage() {
	const { loginWithRedirect } = useAuth0()

	return (
		<div className="flex h-screen items-center justify-center bg-blue-linear px-4">
			<div className="flex h-full max-w-lg flex-col pb-8 pt-16 sm:pt-32 md:justify-center">
				<div className="mb-0 flex h-16 justify-center md:mb-16">
					<img
						className={`h-full`}
						src={IconWhite}
						alt="Paybotic logo"
					/>
				</div>
				<div className="mb-12 mt-auto md:mt-0">
					<h2 className="typography-display-small max-w-[292px] text-white md:max-w-full">
						Convenient, secure solutions for every transaction, today and tomorrow.
					</h2>
				</div>
				<div className="flex flex-col gap-md">
					<Button
						size="lg"
						className="w-full"
						onClick={() =>
							loginWithRedirect({
								authorizationParams: {
									screen_hint: 'signup',
								},
							})
						}
					>
						Register
					</Button>
					<Button
						size="lg"
						className="w-full"
						variant="ghost"
						onClick={() => loginWithRedirect()}
					>
						Log In
					</Button>
					<p className="typography-body-small text-left text-neutral-500">
						Paybotic Financial 2024. All Rights Reserved. Paybotic Financial is a financial technology company, not a
						bank. Banking services are provided by Regent Bank, Member FDIC. FDIC insurance only covers failure of
						insured depository institutions. Certain conditions must be satisfied for pass-through FDIC deposit
						insurance to apply.
					</p>
				</div>
			</div>
		</div>
	)
}
