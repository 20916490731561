import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useRef } from 'react'

import { PurchaseWebsocketStatus } from '../../@types/purchase'

export const usePurchaseWebSocket = (
	purchaseId: string | undefined,
	onUpdate: (purchaseWebsocketStatus: PurchaseWebsocketStatus) => void,
) => {
	const { getAccessTokenSilently } = useAuth0()

	const wsRef = useRef<WebSocket | null>(null)

	useEffect(() => {
		if (!purchaseId) return

		const connectWebsocket = async () => {
			try {
				const baseUrl = new URL(import.meta.env.VITE_PAYMENT_SERVICE_API_URL)
				const accessToken = await getAccessTokenSilently()
				const protocol = baseUrl.protocol === 'https:' ? 'wss' : 'ws'
				const wsURL = `${protocol}://${baseUrl.host}/ws/purchases/${purchaseId}?token=${accessToken}`

				wsRef.current = new WebSocket(wsURL)

				wsRef.current.onopen = () => {
					console.log('Connected to WebSocket')
				}
				wsRef.current.onmessage = (event) => {
					const purchaseWebsocketStatus = JSON.parse(event.data) as PurchaseWebsocketStatus
					onUpdate(purchaseWebsocketStatus)
				}
				wsRef.current.onclose = () => console.log('WebSocket connection closed')
				wsRef.current.onerror = (error) => {
					console.error('WebSocket error:', error)
				}
			} catch (e) {
				console.error(e)
			}
		}

		connectWebsocket()

		return () => wsRef.current?.close()
	}, [purchaseId, onUpdate, getAccessTokenSilently])
}
