import * as LabelPrimitive from '@radix-ui/react-label'
import { Slot } from '@radix-ui/react-slot'
import * as React from 'react'
import {
	Control,
	Controller,
	ControllerProps,
	FieldError,
	FieldErrorsImpl,
	FieldPath,
	FieldValues,
	FormProvider,
	Merge,
	PathValue,
	useFormContext,
} from 'react-hook-form'

import { Label } from '@/components/ui'
import { cn } from '@/lib/utils'

const Form = FormProvider

type ArrayFieldError = Merge<
	FieldError,
	FieldErrorsImpl<{
		value: string
		id: string
	}>
>

const isFormArrayField = (arg: FieldError | ArrayFieldError): arg is ArrayFieldError => {
	return (arg as ArrayFieldError).value !== undefined
}

type FormFieldContextValue<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
	name: TName
}

const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue)

const FormField = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	...props
}: ControllerProps<TFieldValues, TName>) => {
	return (
		<FormFieldContext.Provider value={{ name: props.name }}>
			<Controller {...props} />
		</FormFieldContext.Provider>
	)
}

const useFormField = () => {
	const fieldContext = React.useContext(FormFieldContext)
	const itemContext = React.useContext(FormItemContext)
	const { getFieldState, formState } = useFormContext()

	const fieldState = getFieldState(fieldContext.name, formState)

	if (!fieldContext) {
		throw new Error('useFormField should be used within <FormField>')
	}

	const { id } = itemContext

	return {
		id,
		name: fieldContext.name,
		formItemId: `${id}-form-item`,
		formDescriptionId: `${id}-form-item-description`,
		formMessageId: `${id}-form-item-message`,
		...fieldState,
	}
}

type FormItemContextValue = {
	id: string
}

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue)

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => {
		const id = React.useId()

		return (
			<FormItemContext.Provider value={{ id }}>
				<div
					ref={ref}
					className={cn('space-y-1', className)}
					{...props}
				/>
			</FormItemContext.Provider>
		)
	},
)
FormItem.displayName = 'FormItem'

const FormLabel = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
	const { error, formItemId } = useFormField()

	return (
		<Label
			ref={ref}
			className={cn('typography-label-large-prominent', error && 'text-destructive', className)}
			htmlFor={formItemId}
			{...props}
		/>
	)
})
FormLabel.displayName = 'FormLabel'

const FormControl = React.forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(
	({ ...props }, ref) => {
		const { error, formItemId, formDescriptionId, formMessageId } = useFormField()

		return (
			<Slot
				ref={ref}
				id={formItemId}
				aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
				aria-invalid={!!error}
				{...props}
			/>
		)
	},
)
FormControl.displayName = 'FormControl'

const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
	({ className, ...props }, ref) => {
		const { formDescriptionId } = useFormField()

		return (
			<p
				ref={ref}
				id={formDescriptionId}
				className={cn('text-sm text-muted-foreground', className)}
				{...props}
			/>
		)
	},
)
FormDescription.displayName = 'FormDescription'

type FormFieldError = FieldError | ArrayFieldError | undefined

const FormMessage = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
	({ className, children, ...props }, ref) => {
		const { error, formMessageId } = useFormField()

		const extendedError = error as FormFieldError

		let body
		if (extendedError === undefined) {
			body = children
		} else if (isFormArrayField(extendedError)) {
			body = String(extendedError.value?.message)
		} else {
			body = String(extendedError.message)
		}

		if (!body) {
			return null
		}

		return (
			<p
				ref={ref}
				id={formMessageId}
				className={cn('text-sm font-medium text-destructive', className)}
				{...props}
			>
				{body}
			</p>
		)
	},
)
FormMessage.displayName = 'FormMessage'

const FormComponent = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	label,
	description,
	className,
	render,
	required = false,
	...props
}: {
	label?: React.ReactNode
	description?: React.ReactNode
	className?: string
	required?: boolean
} & ControllerProps<TFieldValues, TName>) => {
	return (
		<FormFieldContext.Provider value={{ name: props.name }}>
			<Controller
				{...props}
				render={(field) => (
					<FormItem className={className}>
						{!!label && (
							<FormLabel>
								{label}
								{required && <span className="ml-1">*</span>}
							</FormLabel>
						)}
						<FormControl>{render(field)}</FormControl>
						{!!description && <FormDescription>{description}</FormDescription>}
						<FormMessage />
					</FormItem>
				)}
			/>
		</FormFieldContext.Provider>
	)
}
FormComponent.displayName = 'FormComponent'

const useFormFieldControlled = <TFieldValues extends FieldValues = FieldValues>(control: Control<TFieldValues>) => {
	return React.useMemo(() => {
		const FormFieldWithControl = <TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
			render,
			...props
		}: Omit<ControllerProps<TFieldValues, TName>, 'control'>) => (
			<FormField
				control={control}
				{...props}
				render={({ field, ...args }) =>
					render({
						field: {
							...field,
							value:
								// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
								field.value === undefined ? ('' as PathValue<TFieldValues, TName>) : field.value,
						},
						...args,
					})
				}
			/>
		)
		return FormFieldWithControl
	}, [control])
}

const useFormComponentControlled = <TFieldValues extends FieldValues = FieldValues>(control: Control<TFieldValues>) => {
	return React.useMemo(() => {
		const FormFieldWithControl = <TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
			render,
			...props
		}: { label?: React.ReactNode; description?: React.ReactNode; className?: string; required?: boolean } & Omit<
			ControllerProps<TFieldValues, TName>,
			'control'
		>) => (
			<FormComponent
				control={control}
				{...props}
				render={({ field, ...args }) =>
					render({
						field: {
							...field,
							value:
								// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
								field.value === undefined ? ('' as PathValue<TFieldValues, TName>) : field.value,
						},
						...args,
					})
				}
			/>
		)
		return FormFieldWithControl
	}, [control])
}

export {
	useFormField,
	Form,
	FormItem,
	FormLabel,
	FormControl,
	FormDescription,
	FormMessage,
	FormField,
	useFormFieldControlled,
	FormComponent,
	useFormComponentControlled,
}
