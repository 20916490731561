import { Route } from 'react-router-dom'

import AuthenticationGuard from '@/components/authentication-guard'
import { ErrorPage } from '@/pages/error-page'
import { LogoutPage } from '@/pages/logout-page'
import { ReEnableUserPage } from '@/pages/re-enable-user'
import RedirectPage from '@/pages/redirect-page'
import { UnauthenticatedPage } from '@/pages/unauthenticated'

export const sharedRoutes = () => {
	return (
		<>
			<Route
				path="/redirect"
				element={<AuthenticationGuard component={RedirectPage} />}
			/>
			<Route
				path="/logout"
				element={<LogoutPage />}
			/>
			<Route
				path="/exception"
				element={<ErrorPage />}
			/>
			<Route
				path="/unauthenticated"
				element={<UnauthenticatedPage />}
			/>
			<Route
				path="/re-enable-user"
				element={<AuthenticationGuard component={ReEnableUserPage} />}
			/>
		</>
	)
}
