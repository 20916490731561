import React from 'react'

import { cn } from '@/lib/utils.ts'

type DashboardLayoutProps = {
	children: React.ReactNode
	className?: string
}

export function DashboardLayout({ children, className = '' }: DashboardLayoutProps) {
	return (
		<div
			className={cn('container mx-auto max-w-[480px] px-4 pb-4 pt-4 md:pt-12', className)}
			data-testid="dashboard-layout"
		>
			{children}
		</div>
	)
}
