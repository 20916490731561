import { Skeleton } from '@/components/ui'
import { cn } from '@/lib/utils'

type MerchantDashboardValueCardProps = {
	label: string
	value: string | number
	isLoading?: boolean
} & React.ComponentPropsWithoutRef<'div'>

export function MerchantDashboardValueCard({
	label,
	value,
	isLoading,
	className,
	...props
}: MerchantDashboardValueCardProps) {
	return (
		<div
			className={cn('space-y-2 rounded-2xl bg-card p-6 text-center text-primary', className)}
			{...props}
		>
			<div className="typography-label-large-prominent">{label}</div>
			<div className="typography-display-small relative">
				{isLoading && <Skeleton className="absolute h-full w-full" />}
				<span className={cn(isLoading && 'invisible')}>{value}</span>
			</div>
		</div>
	)
}
