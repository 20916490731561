import { ReactNode } from 'react'

type Props = {
	text?: ReactNode
}

export const Loading = ({ text }: Props) => {
	return (
		<div
			data-testid="loading"
			className="z-10 flex h-[calc(100vh-150px)] items-center justify-center"
		>
			<div className="relative h-48 w-48">
				<div
					className="absolute inset-0 rounded-full"
					style={{
						border: '6px solid transparent',
						borderTopColor: '#ffa516',
						borderRightColor: '#ffa516',
						animation: 'spin 1s linear infinite',
					}}
				/>
				{text && (
					<div className="absolute inset-0 flex items-center justify-center">
						<span className="text-center text-sm font-semibold">{text}</span>
					</div>
				)}
			</div>
		</div>
	)
}
