import { ArrowRightIcon } from 'lucide-react'
import { Link, useLocation } from 'react-router-dom'

import { Button, Card } from '@/components/ui'

type AddBankAccountCardProps = {
	redirectTo?: string
	returnTo?: string
}

export function AddBankAccountCard({ redirectTo, returnTo }: AddBankAccountCardProps) {
	const location = useLocation()

	return (
		<Card className="flex items-center justify-between rounded-lg border-0 bg-orange-linear px-6 py-16 shadow-lg">
			<h2 className="typography-headline-medium max-w-44">Add New Account</h2>
			<Button
				asChild
				size="icon"
				variant="secondary"
			>
				<Link
					to={{
						pathname: '/link-account',
						search: `?${new URLSearchParams({ redirectTo: redirectTo || '', returnTo: returnTo || location.pathname }).toString()}`,
					}}
				>
					<ArrowRightIcon />
				</Link>
			</Button>
		</Card>
	)
}
