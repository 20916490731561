import { clsx, type ClassValue } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

export const twMerge = extendTailwindMerge<'typography'>({
	extend: {
		classGroups: { typography: [(classPart: string) => classPart.startsWith('typography-')] },
	},
})

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}
