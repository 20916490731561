import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { KyInstance } from 'ky'
import { z } from 'zod'

import { UserSchema } from '@/utils/validations.tsx'
import { User } from '../../models/user.models.ts'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

export type UserData = Partial<z.infer<typeof UserSchema>>

async function createUser(httpInstance: KyInstance, user: UserData) {
	return await httpInstance
		.post('users/self', {
			json: {
				...user,
			},
		})
		.json<User>()
}

export function useCreateUser() {
	const { getAccessTokenSilently } = useAuth0()
	const queryClient = useQueryClient()
	const { paymentServiceClient } = usePaymentService()
	return useMutation({
		mutationFn: async (user: UserData) => {
			return createUser(paymentServiceClient.httpInstance, user)
		},
		onSuccess: async (data) => {
			queryClient.setQueryData<User>(['user'], data)
			await getAccessTokenSilently({
				cacheMode: 'off',
			})
		},
		onError: (error) => {
			// Todo: Notify the user of the error
			console.error(error)
		},
	})
}
