export type RegistrationLayoutProps = {
	title: string
	description?: string
	children: React.ReactNode
}

export function RegistrationLayout({ title, description, children }: RegistrationLayoutProps) {
	return (
		<div className="relative flex h-full max-h-full flex-col gap-lg bg-primary-50 px-4">
			<div className="flex flex-col gap-xs pt-6">
				<h1 className="typography-label-medium-prominent text-neutral-900">Register</h1>
				<h2 className="typography-headline-medium text-primary">{title}</h2>
				{description && <p className="typography-body-medium">{description}</p>}
			</div>

			{children}
		</div>
	)
}
