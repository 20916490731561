import { useAuth0 } from '@auth0/auth0-react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { ErrorBoundary } from '@/components/error/error-boundary.tsx'
import { Loading } from '@/components/loading'
import { ErrorPage } from '@/pages/error-page.tsx'
import { NotFoundPage } from '@/pages/not-found-page.tsx'
import { consumerRoutes } from '@/routes/consumer-routes.tsx'
import { merchantRoutes } from '@/routes/merchant-routes.tsx'
import { publicRoutes } from '@/routes/public-routes.tsx'
import { sharedRoutes } from '@/routes/shared-routes.tsx'

export default function App() {
	const { isLoading, error } = useAuth0()
	const navigate = useNavigate()
	if (isLoading) {
		return <Loading text="Wait a moment..." />
	}

	if (error) {
		return <ErrorPage />
	}

	return (
		<ErrorBoundary
			fallback={ErrorPage}
			navigate={navigate}
		>
			<Routes>
				{publicRoutes()}
				{sharedRoutes()}
				{consumerRoutes()}
				{merchantRoutes()}

				{/* Not Found Route */}
				<Route
					path="*"
					element={<NotFoundPage />}
				/>
			</Routes>
		</ErrorBoundary>
	)
}
