import { useAuth0 } from '@auth0/auth0-react'

import { useUser } from '@/api/hooks/use-user.ts'
import { Loading } from '@/components/loading'
import { Button } from '@/components/ui'
import { DashboardLayout } from '@/modules/consumer/dashboard/dashboard-layout.tsx'
import { SettingsItem } from '@/modules/consumer/settings/settings-item.tsx'
import { getLogoutOptions } from '@/utils/logout-utils.ts'

export function SettingsPage() {
	const { logout, isLoading } = useAuth0()
	const { data: dbUser, isLoading: loadingUser } = useUser()

	if (isLoading || loadingUser) {
		return <Loading />
	}

	return (
		<DashboardLayout>
			<div className="flex flex-col justify-between">
				<h1 className="typography-headline-medium mb-6 text-primary-500">Settings</h1>
				<SettingsItem
					title={`${dbUser?.first_name} ${dbUser?.last_name}`}
					subtitle="Personal Information"
					href={'/dashboard/settings/personal-information'}
					testId="personal-information"
				/>
				<SettingsItem
					title={'Contact Support'}
					href={'/dashboard/settings/contact-support'}
					testId="contact-support"
				/>
				<SettingsItem
					title={'Deactivate Account'}
					href={'/dashboard/settings/deactivate-account'}
					testId="deactivate-account"
				/>
				<div className="typography-body-large flex h-[72px] items-center px-sm py-md">
					<Button
						className="text-secondary-600 underline"
						variant="link"
						onClick={() => logout(getLogoutOptions())}
						data-testid="logout-button"
					>
						Log Out
					</Button>
				</div>
			</div>
		</DashboardLayout>
	)
}
