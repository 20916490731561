import { Landmark, Link, MoreHorizontal, Star, Trash } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Account } from '@/@types/accounts'
import { useIsValidAccount } from '@/api/hooks/use-isValid-account'
import { useMakeAccountPrimary } from '@/api/hooks/use-make-account-primary'
import { useRemoveExternalAccount } from '@/api/hooks/use-remove-external-account'
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogTitle,
	Badge,
	Button,
	Card,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui'
import { cn } from '@/lib/utils'
import { useAlert } from 'src/lib/useAlert'

type Props = {
	account: Account
}

export const AccountCard = ({ account }: Props) => {
	const [open, setOpen] = useState(false)
	const {
		IsPrimary,
		LastFour,
		VendorInstitutionName,
		SynAccountID,
		PendingAmount,
		VendorInstitutionID,
		VendorAccessToken,
	} = account
	const makeAccountPrimaryMutation = useMakeAccountPrimary()
	const removeAccountMutation = useRemoveExternalAccount()
	const { data, isLoading: loadingIsValidAccount } = useIsValidAccount(SynAccountID)
	const navigate = useNavigate()
	const { triggerSnackbar } = useAlert()

	const handleClickDelete = () => {
		if (PendingAmount > 0) {
			triggerSnackbar('Transactions are pending on the account. Please try again later', 'error')
			return
		}
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleMakeAccountPrimary = () => {
		if (account.IsPrimary) {
			return
		}
		makeAccountPrimaryMutation.mutate(SynAccountID)
	}

	const handleRemoveAccount = () => {
		removeAccountMutation.mutate(SynAccountID)
		handleClose()
	}

	const handleRelink = () => {
		navigate({
			pathname: '/link-account',
			search: `?vendorInstitutionID=${VendorInstitutionID}&vendorAccessToken=${VendorAccessToken}`,
		})
	}

	return (
		<>
			<AlertDialog
				open={open}
				onOpenChange={setOpen}
			>
				<AlertDialogContent>
					<AlertDialogTitle className="typography-title-large text-center">Are you sure?</AlertDialogTitle>
					<AlertDialogDescription className="typography-body-large text-center">
						Are you sure you want to delete the bank account {VendorInstitutionName} #{LastFour}?
					</AlertDialogDescription>
					<AlertDialogFooter>
						<AlertDialogCancel
							className="w-full"
							onClick={handleClose}
						>
							Cancel
						</AlertDialogCancel>
						<AlertDialogAction
							onClick={handleRemoveAccount}
							className="w-full bg-destructive text-destructive-foreground hover:bg-destructive/90"
						>
							Delete
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
			<Card
				data-testid="account-card"
				className={cn('w-full border-none bg-blue-linear p-4 transition-all duration-500')}
			>
				<div className="mb-5 flex items-start justify-between">
					<div className="flex w-full items-center gap-2">
						<span className="typography-label-large flex items-center gap-1 py-1 text-white">
							<Landmark className="h-6 w-6" />
							{VendorInstitutionName}
						</span>
						{IsPrimary && <Badge>Primary</Badge>}
						{!data?.isValid && !loadingIsValidAccount && <Badge variant="destructive">Not linked</Badge>}
					</div>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button
								size={'icon'}
								variant="ghost"
								className="h-10 w-10 min-w-10 rounded-full bg-black/20 p-0 text-white hover:bg-black/30"
							>
								<span className="sr-only">Open menu</span>
								<MoreHorizontal className="h-4 w-4" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end">
							{!IsPrimary && data?.isValid && (
								<DropdownMenuItem onClick={handleMakeAccountPrimary}>
									<Star /> Make it primary
								</DropdownMenuItem>
							)}
							{!data?.isValid && !loadingIsValidAccount && (
								<DropdownMenuItem onClick={handleRelink}>
									<Link /> Relink Account
								</DropdownMenuItem>
							)}
							<DropdownMenuItem onClick={handleClickDelete}>
								<Trash /> Delete account
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
				<div className="mt-4 flex items-center justify-between">
					<p className="typography-label-large font-bold text-white">Account #{LastFour}</p>
				</div>
			</Card>
		</>
	)
}
