import { HTTPError } from 'ky'
import { ArrowLeftIcon } from 'lucide-react'
import { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { CreatePurchaseRequest, useCreatePayment } from '@/api/hooks/use-payment'
import { Loading } from '@/components/loading'
import { Button, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui'
import { CheckoutEnterCode } from '@/modules/merchant/checkout/enter-code'
import { ReadQrCode } from '@/modules/merchant/checkout/read-qr-code'
import { useMerchantCheckoutStore } from '@/store/merchant/merchantCheckoutStore'

export function MerchantCheckoutCodePage() {
	const { mutate } = useCreatePayment()
	const purchase = useMerchantCheckoutStore((state) => state.purchase)
	const setPurchaseResponse = useMerchantCheckoutStore((state) => state.setPurchaseResponse)
	const setError = useMerchantCheckoutStore((state) => state.setError)

	const [loading, setLoading] = useState(false)

	const navigate = useNavigate()

	const handleError = useCallback(
		async (errorMessage: string | undefined) => {
			setLoading(false)
			console.log(errorMessage)
			setError(errorMessage || 'An error occurred')
			navigate('/merchant/checkout/error')
		},
		[navigate, setError],
	)

	const handleHTTPError = useCallback(
		async (err: HTTPError | null) => {
			console.log(err)
			const res = await err?.response?.json()
			handleError(res?.message)
		},
		[handleError],
	)

	const handlePayment = ({ purchaseId, paymentCode }: Pick<CreatePurchaseRequest, 'purchaseId' | 'paymentCode'>) => {
		if (!purchase) return

		setLoading(true)
		mutate(
			{
				purchaseId,
				paymentCode,
				amount: purchase?.price,
				notes: purchase?.notes,
				pos_order_id: purchase?.posOrderId,
			},
			{
				onSuccess: (data) => {
					setLoading(false)
					if (data) {
						setPurchaseResponse(data)
						navigate('/merchant/checkout/awaiting')
					} else {
						setError('An error occurred')
						navigate('/merchant/checkout/error')
					}
				},
				onError: async (error) => {
					if (error instanceof HTTPError) {
						handleHTTPError(error)
					} else {
						handleError(error?.message)
					}
				},
			},
		)
	}

	const handleScanPayment = (data: string) => {
		const purchaseId = data.split('/').pop()
		handlePayment({
			purchaseId,
		})
	}

	const handlePaymentCode = (paymentCode: string) => {
		handlePayment({
			paymentCode,
		})
	}

	// this is needed to mimic the QR Code read in the E2E test
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	;(window as any).onScan = handleScanPayment

	if (loading) {
		return <Loading text="Wait a moment..." />
	}

	return (
		<div
			data-testid="checkout-code"
			className="relative flex h-full w-full bg-neutral-50"
		>
			<div className="absolute left-0 top-0 z-40 flex w-full items-center justify-center px-4">
				<div className="w-full max-w-md py-4">
					<Button
						asChild
						variant="tertiary"
						size="icon"
						className="rounded-full"
					>
						<Link
							replace
							to="/merchant/checkout"
						>
							<ArrowLeftIcon />
						</Link>
					</Button>
				</div>
			</div>

			<Tabs
				defaultValue="qr"
				className="h-full w-full"
			>
				<div className="relative z-40 mt-20 flex w-full justify-center px-4">
					<div className="w-full max-w-md px-4">
						<TabsList className="mx-auto grid w-full max-w-md grid-cols-2">
							<TabsTrigger value="qr">Scan QR Code</TabsTrigger>
							<TabsTrigger value="code">Enter Payment Code</TabsTrigger>
						</TabsList>
					</div>
				</div>

				<TabsContent value="qr">
					<ReadQrCode callback={handleScanPayment} />
				</TabsContent>
				<TabsContent value="code">
					<CheckoutEnterCode callback={handlePaymentCode} />
				</TabsContent>
			</Tabs>
		</div>
	)
}
