import { Route } from 'react-router-dom'

import AuthenticationGuard from '@/components/authentication-guard'
import { MyPermissions } from '@/constants/my-permissions'
import { MyRole } from '@/constants/my-role'
import { MerchantLayout } from '@/layout/merchant/MerchantLayout'
import { MerchantCheckoutAwaiting } from '@/pages/merchant/checkout/merchant-checkout-awaiting-page'
import { MerchantCheckoutCancelled } from '@/pages/merchant/checkout/merchant-checkout-cancelled-page'
import { MerchantCheckoutCodePage } from '@/pages/merchant/checkout/merchant-checkout-code-page'
import { MerchantCheckoutError } from '@/pages/merchant/checkout/merchant-checkout-error-page'
import { MerchantCheckoutPage } from '@/pages/merchant/checkout/merchant-checkout-page'
import { MerchantCheckoutSuccess } from '@/pages/merchant/checkout/merchant-checkout-success-page'
import { MerchantHomePage } from '@/pages/merchant/merchant-home-page'
import { RegistrationPage } from '@/pages/merchant/merchant-registration-page'
import { MerchantReportsPage } from '@/pages/merchant/merchant-reports-page'
import { MerchantSupportPage } from '@/pages/merchant/merchant-support-page'
import { ListBusinessUsersPage } from '@/pages/merchant/merchant-users-page'

export const merchantRoutes = () => {
	return (
		<>
			<Route path="/merchant">
				{/* Layout Routes */}
				<Route element={<MerchantLayout />}>
					<Route
						index
						element={
							<AuthenticationGuard
								component={MerchantHomePage}
								requiredPermissions={[MyPermissions.ViewDashboard]}
							/>
						}
					/>
					<Route
						path="registration"
						element={
							<AuthenticationGuard
								component={RegistrationPage}
								requiredRoles={[MyRole.AdminAccount]}
							/>
						}
					/>
					<Route
						path="checkout"
						element={
							<AuthenticationGuard
								component={MerchantCheckoutPage}
								requiredPermissions={[MyPermissions.CreateACHDebit]}
							/>
						}
					/>
					<Route
						path="report"
						element={
							<AuthenticationGuard
								component={MerchantReportsPage}
								requiredPermissions={[MyPermissions.ViewReports]}
							/>
						}
					/>
					<Route
						path="support"
						element={
							<AuthenticationGuard
								component={MerchantSupportPage}
								requiredPermissions={[MyPermissions.ViewSupport]}
							/>
						}
					/>
					<Route
						path="users"
						element={
							<AuthenticationGuard
								component={ListBusinessUsersPage}
								requiredPermissions={[MyPermissions.ReadBusinessUsers]}
							/>
						}
					/>
				</Route>

				{/* Non-Layout Routes */}
				<Route
					path="checkout/code"
					element={
						<AuthenticationGuard
							component={MerchantCheckoutCodePage}
							requiredPermissions={[MyPermissions.CreateACHDebit]}
						/>
					}
				/>
				<Route
					path="checkout/awaiting"
					element={
						<AuthenticationGuard
							component={MerchantCheckoutAwaiting}
							requiredPermissions={[MyPermissions.CreateACHDebit]}
						/>
					}
				/>
				<Route
					path="checkout/success"
					element={
						<AuthenticationGuard
							component={MerchantCheckoutSuccess}
							requiredPermissions={[MyPermissions.CreateACHDebit]}
						/>
					}
				/>
				<Route
					path="checkout/error"
					element={
						<AuthenticationGuard
							component={MerchantCheckoutError}
							requiredPermissions={[MyPermissions.CreateACHDebit]}
						/>
					}
				/>
				<Route
					path="checkout/cancelled"
					element={
						<AuthenticationGuard
							component={MerchantCheckoutCancelled}
							requiredPermissions={[MyPermissions.CreateACHDebit]}
						/>
					}
				/>
			</Route>
		</>
	)
}
