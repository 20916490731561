import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Form, useFormComponentControlled } from '@/components/ui/form/form.tsx'
import { Combobox, Input } from '@/components/ui/index.ts'
import { usStates } from '@/utils/us-states.ts'
import { UserSchema } from '@/utils/validations.tsx'
import { RegistrationFormLayout } from './RegistrationFormLayout.tsx'
import { RegistrationLayout } from './RegistrationLayout.tsx'

const homeAddressSchema = UserSchema.pick({ address: true }).shape.address

type HomeAddressFormData = z.infer<typeof homeAddressSchema>

export type HomeAddressFormProps = {
	defaultValues?: { address?: Partial<z.infer<typeof homeAddressSchema>> }
	onSubmit: (data: { address: HomeAddressFormData }) => void
}

export default function HomeAddressForm({ defaultValues, onSubmit }: HomeAddressFormProps) {
	const form = useForm<HomeAddressFormData>({
		defaultValues: { ...defaultValues?.address },
		resolver: zodResolver(homeAddressSchema),
	})
	const FormComponent = useFormComponentControlled(form.control)

	return (
		<RegistrationLayout
			title="Home Address"
			description="In order to open an account, your current address must be within the United States."
		>
			<Form {...form}>
				<RegistrationFormLayout
					isSubmitting={form.formState.isSubmitting}
					onSubmit={form.handleSubmit((data: HomeAddressFormData) => {
						onSubmit({ address: data })
					})}
				>
					<FormComponent
						required
						name="street_one"
						label="Street Address"
						render={({ field }) => (
							<Input
								{...field}
								placeholder="Address Line 1"
							/>
						)}
					/>
					<FormComponent
						name="street_two"
						render={({ field }) => (
							<Input
								{...field}
								placeholder="Address Line 2"
							/>
						)}
					/>

					<FormComponent
						required
						name="city"
						label="City"
						render={({ field }) => (
							<Input
								{...field}
								placeholder="City"
							/>
						)}
					/>

					<FormComponent
						required
						name="state"
						label="State/Territory"
						render={({ field }) => (
							<Combobox
								options={usStates}
								value={field.value}
								onValueChange={field.onChange}
							/>
						)}
					/>

					<FormComponent
						required
						name="postal_code"
						label="Zip Code"
						render={({ field }) => (
							<Input
								{...field}
								onChange={(e) => {
									e.preventDefault()
									if (e.target.value.length <= 5) {
										field.onChange(e.target.value)
									}
								}}
								placeholder="Zip Code"
							/>
						)}
					/>
				</RegistrationFormLayout>
			</Form>
		</RegistrationLayout>
	)
}
