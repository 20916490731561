import { useMutation, useQueryClient } from '@tanstack/react-query'
import { KyInstance } from 'ky'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTimeout } from 'usehooks-ts'

import { useConsumerPurchaseStore } from '@/store/consumer/consumerPurchaseStore'
import { usePaymentService } from '../services/providers/payment-service-provider'

export type InitialPurchaseResponse = {
	ID: string
	PurchaseDate: Date
	Expiration: Date
	Status: string
	PublicId: string
	CreatedAt: Date
	UpdatedAt: Date
	CustomerAccountID: number
}

async function createPurchase(httpInstance: KyInstance) {
	return await httpInstance.post('purchases').json<InitialPurchaseResponse>()
}

export function usePurchase() {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { paymentServiceClient } = usePaymentService()
	const setPurchase = useConsumerPurchaseStore((state) => state.setPurchase)
	const [qrCodeExpired, setQrCodeExpired] = useState(false)
	const [timeRemaining, setTimeRemaining] = useState(0)

	useTimeout(() => {
		if (purchase) {
			setQrCodeExpired(true)
			setTimeRemaining(0)
		}
	}, timeRemaining)

	const updateQrCode = useCallback((data: InitialPurchaseResponse) => {
		const expirationTime = new Date(data.Expiration).getTime()
		const currentTime = new Date().getTime()
		const timeRemainingCounter = expirationTime - currentTime - 10000
		setTimeRemaining(timeRemainingCounter)
		setQrCodeExpired(false)
	}, [])

	const {
		mutate: addPurchase,
		error: unhandledError,
		data: purchase,
		...other
	} = useMutation({
		mutationFn: async () => {
			return createPurchase(paymentServiceClient.httpInstance)
		},
		onSuccess: async (data) => {
			queryClient.setQueryData<InitialPurchaseResponse>(['purchase'], data)
			updateQrCode(data)
			setPurchase(data)
		},
		onError: async () => {
			// Todo: send error to sentry
			navigate('/exception')
		},
	})

	return { addPurchase, unhandledError, purchase, qrCodeExpired, ...other }
}
