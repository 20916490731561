import { zodResolver } from '@hookform/resolvers/zod'
import { createColumnHelper } from '@tanstack/react-table'
import { DownloadIcon } from 'lucide-react'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { ReportData } from '@/@types/merchant'
import { useGenerateReport } from '@/api/hooks/use-generate-report'
import { useReport } from '@/api/hooks/use-report'
import { DataTable, DataTableColumnHeader } from '@/components/data-table'
import { DataTableColumnExpandButton } from '@/components/data-table/data-table-column-expand-button'
import { DateRangeInput } from '@/components/inputs/date-range-input'
import { Button, Form, useFormComponentControlled } from '@/components/ui'
import { cn } from '@/lib/utils.ts'
import { dateTimeFormatter, dayjs } from '@/utils/dates.ts'
import { formatCentsToCurrency } from '@/utils/money-utils.ts'

const formSchema = z.object({
	store: z.string().optional(),
	dateRange: z.object({
		from: z.date().optional(),
		to: z.date().optional(),
	}),
})

const oneMonth = dayjs().subtract(1, 'month').toDate()
const today = dayjs().toDate()

const defaultValues = {
	store: '',
	dateRange: {
		from: oneMonth,
		to: today,
	},
}

const columnHelper = createColumnHelper<ReportData>()

const columns = [
	columnHelper.display({
		id: 'expand',
		cell: ({ row }) => <DataTableColumnExpandButton row={row} />,
		meta: {
			className: cn('w-0 p-0 pl-1'),
		},
	}),
	columnHelper.accessor('DateTimeCreated', {
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Date"
			/>
		),
		cell: ({ cell }) => dateTimeFormatter.format(new Date(cell.getValue())),
		enableSorting: false,
		meta: {
			className: cn('whitespace-nowrap'),
		},
	}),
	columnHelper.accessor('BaseAmount', {
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Sales"
			/>
		),
		cell: ({ cell }) => formatCentsToCurrency(cell.getValue()),
		enableSorting: false,
		meta: {
			className: 'text-right',
		},
	}),
	columnHelper.accessor('TransactionStatus', {
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Status"
			/>
		),
		enableSorting: false,
	}),
	columnHelper.accessor('CustomerName', {
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Customer"
			/>
		),
		enableSorting: false,
		meta: {
			className: cn('text-nowrap'),
		},
	}),
	columnHelper.accessor('TotalPayment', {
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Settlement Amt"
			/>
		),
		cell: ({ cell }) => formatCentsToCurrency(cell.getValue()),
		enableSorting: false,
		meta: {
			className: cn('whitespace-nowrap text-right'),
		},
	}),
	columnHelper.accessor('DateTimeOfApproval', {
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Settlement Time"
			/>
		),
		cell: ({ cell }) => dateTimeFormatter.format(new Date(cell.getValue())),
		enableSorting: false,
		meta: {
			className: cn('text-nowrap'),
		},
	}),
]

const getRowCanExpand = () => true

export function TransactionsReport({ className }: { className?: string }) {
	const form = useForm({
		defaultValues,
		resolver: zodResolver(formSchema),
	})
	const FormComponent = useFormComponentControlled(form.control)

	const { from, to } = form.watch('dateRange')
	const storeId = form.watch('store')

	const reportParams = useMemo(() => {
		return {
			store_id: storeId,
			start_date: dayjs(from).format('YYYY-MM-DDT00:00:00Z'),
			end_date: dayjs(to).format('YYYY-MM-DDT00:00:00Z'),
		}
	}, [from, storeId, to])

	const generateReportMutation = useGenerateReport()
	const { data: reportData, isLoading: reportLoading } = useReport(reportParams)

	const generateReport = useCallback(() => {
		generateReportMutation.mutate(reportParams)
	}, [generateReportMutation, reportParams])

	return (
		<div
			data-testid="transactions-report"
			className={cn('space-y-2 rounded-lg bg-card p-4', className)}
		>
			<div className="flex flex-wrap justify-end gap-2">
				<Form {...form}>
					<form className="flex gap-2">
						<FormComponent
							required
							name="dateRange"
							render={({ field }) => (
								<DateRangeInput
									onSelect={field.onChange}
									selected={field.value}
								/>
							)}
						/>
					</form>
				</Form>
				<Button
					variant="outline"
					onClick={generateReport}
				>
					<DownloadIcon /> <span className="sr-only sm:not-sr-only">Download</span>
				</Button>
			</div>
			<DataTable
				isLoading={reportLoading}
				columns={columns}
				data={reportData ?? []}
				getRowCanExpand={getRowCanExpand}
				getExpandedRow={(row) => [
					{ header: 'Bank Account', value: row.LastFour },
					{ header: 'Transaction ID', value: row.PublicID },
					{ header: 'Fee', value: formatCentsToCurrency(row.FeeApprovedAmount) },
				]}
			/>
		</div>
	)
}
