import { Fragment, useMemo } from 'react'

import { Separator } from '@/components/ui'
import { cn } from '@/lib/utils'

type OrderDetailsProps = {
	className?: string
	storeName: string
	transactionDate: string
	posOrderId?: string | null
	totalPrice: string
	notes?: string
	customerName?: string | null
	transactionId?: string | null
}

export function OrderDetails(props: OrderDetailsProps) {
	const details = useMemo(
		() => [
			{ label: 'Store Name', value: props.storeName },
			{ label: 'Transaction Date', value: props.transactionDate },
			{ label: 'POS Order ID', value: props.posOrderId || '-' },
			{ label: 'Total Price', value: props.totalPrice },
			{ label: 'Notes', value: props.notes || '-' },
			{ label: 'Customer Name', value: props.customerName },
			{ label: 'Transaction ID', value: props.transactionId },
		],
		[
			props.customerName,
			props.notes,
			props.posOrderId,
			props.storeName,
			props.totalPrice,
			props.transactionDate,
			props.transactionId,
		],
	)

	return (
		<section className={cn('w-full rounded-lg bg-primary-50 p-6', props.className)}>
			<h2 className="typography-label-large-prominent">Order Details</h2>
			<Separator className="my-4 bg-primary-400" />
			<dl className="grid grid-cols-[max-content_1fr] gap-x-4 gap-y-2">
				{details.map(({ label, value }) =>
					value ? (
						<Fragment key={label}>
							<dt className="typography-label-large-prominent">{label}</dt>
							<dd
								className="typography-body-medium"
								data-testid={`order-details-value-${label.toLowerCase().replaceAll(' ', '-')}`}
							>
								{value}
							</dd>
						</Fragment>
					) : null,
				)}
			</dl>
		</section>
	)
}
