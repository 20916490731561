import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

import { Button, Calendar, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui'
import { cn } from '@/lib/utils'

type DatePickerProps = {
	value: Date | undefined
	onChange: (date: Date | undefined) => void
	placeholder?: string
	dateFormat?: string
	showIcon?: boolean
}

export function DatePicker({
	value,
	onChange,
	placeholder = 'Pick a date',
	dateFormat = 'MM/dd/yyyy',
	showIcon = false,
}: DatePickerProps) {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant={'outline'}
					className={cn('w-full justify-start bg-white text-left font-normal', !value && 'text-muted-foreground')}
				>
					{showIcon && <CalendarIcon className="mr-2 h-4 w-4" />}
					{value ? format(value, dateFormat) : <span>{placeholder}</span>}
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-auto p-0">
				<Calendar
					selected={value}
					mode="single"
					onSelect={onChange}
					initialFocus
					today={new Date()}
					defaultMonth={new Date()}
				/>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
