import { Route } from 'react-router-dom'

import AuthenticationGuard from '@/components/authentication-guard'
import { RegistrationPage } from '@/pages/consumer/registration-page'
import LandingPage from '@/pages/landing-page'
import { LoginPage } from '@/pages/login-page'

export const publicRoutes = () => {
	return (
		<>
			<Route
				path="/"
				element={<LandingPage />}
			/>
			<Route
				path="/login"
				element={<LoginPage />}
			/>
			<Route
				path="/registration"
				element={<AuthenticationGuard component={RegistrationPage} />}
			/>
		</>
	)
}
