import { useMutation, useQueryClient } from '@tanstack/react-query'
import { HTTPError, KyInstance } from 'ky'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { validatePassword } from '@/validations/auth0-password-validation.ts'
import { useAlert } from '../../lib/useAlert.tsx'
import { ErrorCode, parseHttpError } from '../errors.ts'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

export const BusinessUser = z.object({
	email: z.string().email({ message: 'Invalid email address' }),
	username: z.string().min(1, { message: 'Username required' }).max(15, { message: '15 character max' }),
	password: z.string().refine(validatePassword, {
		message:
			'Password must be at least 8 characters, contain at least one lowercase letter, one uppercase letter, and one number or special character',
	}),
	role: z.string().min(1, { message: 'Role required' }),
})

export type BusinessUserType = z.infer<typeof BusinessUser>

const createBusinessUser = async (httpInstance: KyInstance, businessUser: BusinessUserType) => {
	return await httpInstance
		.post('businesses/user', {
			json: {
				...businessUser,
			},
		})
		.json<BusinessUserType>()
}

export const useCreateBusinessUser = () => {
	const { triggerSnackbar } = useAlert()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { paymentServiceClient } = usePaymentService()
	const createBusinessUserMutation = useMutation<BusinessUserType, HTTPError, BusinessUserType, BusinessUserType>({
		mutationFn: async (newBusinessUser: BusinessUserType) => {
			return createBusinessUser(paymentServiceClient.httpInstance, newBusinessUser)
		},
		onSuccess: async (data: BusinessUserType) => {
			// Todo: Will need to update the query to add to list of business users
			queryClient.setQueryData<BusinessUserType>(['/merchant/users'], data)
			triggerSnackbar('User created successfully', 'success')
			navigate('/merchant/users')
		},
		onError: async (error) => {
			const { parsed, data } = await parseHttpError(error)
			if (parsed && data?.code === ErrorCode.ErrUserExists) {
				triggerSnackbar('User already exists', 'error')
			} else {
				triggerSnackbar('Something went wrong creating user', 'error')
			}
		},
	})
	return { createBusinessUserMutation }
}
