import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAccounts } from '@/api/hooks/use-accounts'
import { usePurchasePower } from '@/api/hooks/use-purchase-power.ts'
import { useUser } from '@/api/hooks/use-user'
import { Button, Skeleton } from '@/components/ui'
import { useAlert } from '@/lib/useAlert.tsx'
import { AddBankAccountCard } from '@/modules/consumer/accounts/add-bank-account-card'
import { DashboardLayout } from '@/modules/consumer/dashboard/dashboard-layout'
import { PurchasePower } from '@/modules/consumer/dashboard/purchase-power.tsx'
import { RecentTransactions } from '@/modules/consumer/dashboard/recent-transactions.tsx'

export function DashboardPage() {
	const { data: dbUser } = useUser()
	const { data: accounts, isLoading: accountsLoading } = useAccounts()
	const { data: purchasePower, isLoading, error } = usePurchasePower()
	const { triggerSnackbar } = useAlert()
	const navigate = useNavigate()

	useEffect(() => {
		if (error && error.message !== 'ITEM_LOGIN_REQUIRED') {
			triggerSnackbar('Failed to fetch purchase power')
		}
	}, [error, triggerSnackbar])

	const handlePayNow = () => {
		if (accounts?.length) {
			navigate('/payment')
		} else {
			navigate('/link-account?redirectTo=/payment')
		}
	}

	return (
		<DashboardLayout>
			<div className="flex flex-col justify-between gap-6">
				<h1 className="typography-headline-medium text-primary-500">Hi, {dbUser?.first_name}!</h1>
				{accountsLoading || isLoading ? (
					<>
						<Skeleton className="flex h-[188px] w-full flex-col gap-12 rounded-lg border-0 bg-blue-linear p-6 text-white shadow-lg" />
						<Skeleton className="flex h-[48px] w-full flex-col gap-12 rounded-lg border-0 bg-orange-linear p-6 text-white shadow-lg" />
					</>
				) : accounts?.length === 0 ? (
					<AddBankAccountCard redirectTo="/dashboard" />
				) : (
					<>
						<PurchasePower purchasePower={purchasePower} />
						<Button
							data-testid="pay-now"
							onClick={handlePayNow}
							size="lg"
							className="w-full"
						>
							Pay Now
						</Button>
					</>
				)}

				<RecentTransactions />
			</div>
		</DashboardLayout>
	)
}
